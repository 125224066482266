import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { IonicModule } from '@ionic/angular';
import { MainMenuItemComponent } from './main-menu-item/main-menu-item.component';
import { MainMenuUserInfoComponent } from './main-menu-user-info/main-menu-user-info.component';
import { MainMenuComponent } from './main-menu/main-menu.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        IonicModule,
    ],
    declarations: [
        MainMenuUserInfoComponent,
        MainMenuItemComponent,
        MainMenuComponent,
    ],
    exports: [
        MainMenuUserInfoComponent,
        MainMenuItemComponent,
        MainMenuComponent,
    ],
    providers: [
        InAppBrowser,
    ],
})
export class MainMenuSharedModule { }

import { JOB_PLACEMENTS_CONFIG_TOKEN, JobsPlacementsConfig } from '@actassa/jobs-placements';
import { Inject, Injectable, Optional } from '@angular/core';
import { defaultsDeep } from 'lodash-es';
import { RoutesDictionary } from '../dictionaries/routes.dictionary';
import { JobStatus } from '../enums/job-status.enum';
import { JobChangeDictionary } from '../interfaces/job-change-dictionary.interface';

const JOB_CHANGE_DICTIONARY: JobChangeDictionary = {
    [JobStatus.CURRENT_APPLICATION]: {
        message: 'Thank you. Your request has been sent.',
        url: RoutesDictionary.APPLICATIONS,
    },
    [JobStatus.INTERESTED_SOME_SHIFTS]: {
        message: 'Thank you. Your request has been sent.',
        url: RoutesDictionary.APPLICATIONS,
    },
    [JobStatus.DECLINED_OPPORTUNITY]: {
        message: 'Done. While it\'s still open, you can find it in History and change your response.',
        url: RoutesDictionary.OPPORTUNITIES,
    },
};

@Injectable()
export class JobChangeDictionaryService {
    constructor(
        @Inject(JOB_PLACEMENTS_CONFIG_TOKEN) @Optional() private readonly jobsPlacementsConfig?: JobsPlacementsConfig,
    ) { }

    public get(): JobChangeDictionary {
        return defaultsDeep(this.jobsPlacementsConfig?.jobChangeDictionary || {}, JOB_CHANGE_DICTIONARY);
    };
}
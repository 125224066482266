import { MainMenuSharedModule } from '@actassa/shared';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouteReuseStrategy } from '@angular/router';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { Geofence } from '@ionic-native/geofence/ngx';
// import { GeofenceService } from './services/geofence.service';
import { OpenNativeSettings } from '@ionic-native/open-native-settings/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { NgxsModule } from '@ngxs/store';
import { JobsPlacementsState } from './+state/app-state/app.state';
import { MenuItemsComponent } from './components/menu-items/menu-items.component';
import { JobsPlacementsRoutingModule } from './jobs-placements-routing.module';
import { JobsPlacementsComponent } from './jobs-placements.component';
import { EmptyTextService } from './services/empty-text-service.service';
import { JobChangeDictionaryService } from './services/job-change-dictionary.service';
import { JobsService } from './services/jobs.service';
import { MenuItemsService } from './services/menu-items.service';
import { PlacementsService } from './services/placements.service';
import { PushHandlerService } from './services/push-handler.service';
import { ReportsService } from './services/reports.service';
import { SchedulerService } from './services/scheduler.service';
import { ShiftsService } from './services/shifts.service';
import { TitlesService } from './services/titles.service';

@NgModule({
    declarations: [
        JobsPlacementsComponent,
        MenuItemsComponent,
    ],
    imports: [
        CommonModule,
        IonicModule,
        JobsPlacementsRoutingModule,
        MainMenuSharedModule,
        NgxsModule.forFeature([JobsPlacementsState]),
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        Diagnostic,
        EmptyTextService,
        Geofence,
        OpenNativeSettings,
        TitlesService,
        MenuItemsService,
        JobChangeDictionaryService,
    ],
    exports: [
        MenuItemsComponent,
    ],
})
export class JobsPlacementsModule {
    public static forRoot(): ModuleWithProviders<JobsPlacementsModule> {
        return {
            ngModule: JobsPlacementsModule,
            providers: [
                MenuItemsService,
                JobsService,
                PlacementsService,
                PushHandlerService,
                ReportsService,
                SchedulerService,
                ShiftsService,
            ],
        };
    }
}

// import { Component, OnInit } from '@angular/core';

// import { Platform } from '@ionic/angular';
// import { SplashScreen } from '@ionic-native/splash-screen/ngx';
// import { StatusBar } from '@ionic-native/status-bar/ngx';

import { ApplicationsEnum, AUTH_SERVICE_TOKEN, MobileApplicationConfig, PlatformEnum } from '@actassa/api';
import { AppSettingsService, APP_SETTINGS_TOKEN, NavigationService } from '@actassa/shared';
import {
    Component,
    OnInit,
    OnDestroy,
    ViewChildren,
    QueryList,
    AfterViewInit,
    ChangeDetectionStrategy,
    Inject,
} from '@angular/core';
import {
    Plugins,
    DeviceInfo,
    StatusBarStyle,
} from '@capacitor/core';
import {
    Platform,
    IonRouterOutlet,
} from '@ionic/angular';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select } from '@ngxs/store';
import { UserInterface } from '@actassa/api';
import { isEqual } from 'lodash-es';
import { Subscription, Observable, merge, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';
import { AlertService } from 'services/alert.service';
import { AppStateService } from 'services/app-state.service';
import { ApplicationService } from 'services/application.service';
import { AuthService } from 'services/auth.service';
import { BackButtonService } from 'services/back-button.service';
import { LoadingService } from 'services/loading.service';
import { PushHandlerService } from 'services/push-handler.service';
import { SchedulerService } from 'services/scheduler.service';
import { ToastService } from 'services/toast.service';
import { SetAppSettings } from 'state/root-state/actions/set-app-settings';
import { SetDeviceID } from 'state/root-state/actions/set-device-id';
import { SetUserOffline } from 'state/root-state/actions/set-user-offline';
import { SetUserOnline } from 'state/root-state/actions/set-user-online';
import { RootState } from 'state/root-state/root.state';

const { SplashScreen, StatusBar, Device } = Plugins;

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
    @Select(RootState.isAuthenticated$) public isAuthenticated$: Observable<boolean>;
    @Select(RootState.isAppStateActive$) public isAppStateActive$: Observable<boolean>;
    @Select(RootState.user$) public user$: Observable<UserInterface>;
    @Select(RootState.app$) public app$: Observable<ApplicationsEnum>;

    @ViewChildren(IonRouterOutlet) routerOutlets: QueryList<IonRouterOutlet>;

    private subscriptions = new Subscription();

    constructor(
        @Inject(APP_SETTINGS_TOKEN) private readonly appSettingsService: AppSettingsService,
        @Inject(AUTH_SERVICE_TOKEN) private readonly auth: AuthService,
        private readonly applicationService: ApplicationService,
        private readonly appStateService: AppStateService,
        private readonly backButtonService: BackButtonService,
        private readonly loadingService: LoadingService,
        private readonly platform: Platform,
        private readonly pushHandlerService: PushHandlerService,
        private readonly schedulerService: SchedulerService,
        private readonly toastService: ToastService,
        private readonly alertService: AlertService,
        private readonly navigationService: NavigationService,
    ) {
        this.navigationService.startSaveHistory();
    }

    ngOnInit() {
        this.initializeApp();

        const subscription = merge(
            this.auth.init(),
            this.loadingService.init(),
            this.toastService.init(),
            this.alertService.init(),
            this.toastService.init(),
            this.applicationService.init(),
            this.isAuthenticated$
                .pipe(
                    distinctUntilChanged(),
                    filter((isAuthenticated: boolean) => isAuthenticated),
                    tap(() => {
                        this.pushHandlerService.init();
                    }),
                    switchMap(() => this.schedulerService.sendAwaitingDeviceToken$()),
                ),
            combineLatest([
                this.isAppStateActive$.pipe(distinctUntilChanged()),
                this.isAuthenticated$.pipe(distinctUntilChanged()),
                this.user$.pipe(distinctUntilChanged(isEqual)),
                this.app$.pipe(distinctUntilChanged()),
            ])
                .pipe(
                    filter(([, isAuthenticated, { uuid }, app]: [boolean, boolean, UserInterface, ApplicationsEnum]) =>
                        isAuthenticated && Boolean(uuid) && Boolean(app)),
                    tap(([isAppStateActive, , user, app]: [boolean, boolean, UserInterface, ApplicationsEnum]) => {
                        const { uuid, firstName, lastName } = user;

                        if (!isAppStateActive) {
                            this.setUserOffline(uuid);

                            return;
                        }

                        this.setUserOnline(uuid, `${firstName} ${lastName}`, app);
                    }),
                    switchMap(() => this.appSettingsService.getSettings$()
                        .pipe(tap(payload => this.setAppSettings(payload))),
                    ),
                ),
        )
            .subscribe();

        this.subscriptions.add(subscription);

        this.appStateService.init();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    ngAfterViewInit() {
        // Initialize BackButton Eevent.
        this.backButtonService.backButtonEvent(this.routerOutlets);
    }

    private initializeApp(): void {
        Device.getInfo()
            .then((info: DeviceInfo) => {
                const { platform, uuid } = info;

                this.setDeviceID(uuid);

                if (platform !== PlatformEnum.WEB) {
                    this.platform.ready().then(() => {
                        StatusBar.setStyle({ style: StatusBarStyle.Light });
                        // StatusBar.setOverlaysWebView({
                        //     overlay: true
                        // });
                        SplashScreen.hide();
                    });

                }
            });
    }

    @Dispatch()
    private setDeviceID(deviceID: string): SetDeviceID {
        return new SetDeviceID(deviceID);
    }

    @Dispatch()
    private setUserOnline(uuid: string, name: string, app: ApplicationsEnum): SetUserOnline {
        return new SetUserOnline(uuid, name, app);
    }

    @Dispatch()
    private setUserOffline(uuid: string): SetUserOffline {
        return new SetUserOffline(uuid);
    }

    @Dispatch()
    private setAppSettings(settings: Partial<MobileApplicationConfig>): SetAppSettings {
        return new SetAppSettings(settings);
    }
}

import { ApplicationsEnum, Environment, ENVIRONMENT_TOKEN, MobileApplicationConfig } from '@actassa/api';
import { AppPathsDictionary } from '@actassa/api';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Navigate } from '@ngxs/router-plugin';
import { Actions, ofActionDispatched, Select } from '@ngxs/store';
import { isEqual } from 'lodash-es';
import { Socket } from 'ngx-socket-io';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { SelectRole } from 'state/root-state/actions/select-role';
import { SetActiveApp } from 'state/root-state/actions/set-active-app';
import { SetUserOffline } from 'state/root-state/actions/set-user-offline';
import { SetUserOnline } from 'state/root-state/actions/set-user-online';
import { RootState } from 'state/root-state/root.state';
import { AddMessage } from '../modules/messaging/+state/actions/add-message';
import { SetMessages } from '../modules/messaging/+state/actions/set-messages';
import { SetRoom } from '../modules/messaging/+state/actions/set-room';
import { SetRooms } from '../modules/messaging/+state/actions/set-rooms';
import { UpdateMessage } from '../modules/messaging/+state/actions/update-message';
import { UserOffline } from '../modules/messaging/+state/actions/user-offline';
import { UserOnline } from '../modules/messaging/+state/actions/user-online';
import { Message } from '../modules/messaging/models/message';
import { Room } from '../modules/messaging/models/room';

@Injectable({
    providedIn: 'root',
})
export class ApplicationService {
    @Select(RootState.appSettings$) private appSettings$: Observable<Partial<MobileApplicationConfig>>;
    private socketActivator$ = new BehaviorSubject<SetUserOffline | SetUserOnline | null>(null);

    constructor(
        @Inject(ENVIRONMENT_TOKEN) private readonly environment: Environment,
        private readonly actions$: Actions,
        private readonly http: HttpClient,
        private readonly socket: Socket,
    ) { }

    public init(): Observable<unknown> {
        return merge(
            this.actions$
                .pipe(
                    ofActionDispatched(SetActiveApp),
                    map(({ app }: SetActiveApp) => app),
                    filter((app: ApplicationsEnum) => Boolean(app)),
                    delay(0),
                    switchMap((app: ApplicationsEnum) => this.setServerActiveApplication$(app)),
                ),
            this.actions$
                .pipe(
                    ofActionDispatched(SelectRole),
                    map(({ config }: SelectRole) => config.role),
                    filter((app: ApplicationsEnum) => Boolean(app)),
                    delay(0),
                    switchMap((app: ApplicationsEnum) => this.setServerActiveApplication$(app)),
                ),
            this.actions$
                .pipe(
                    ofActionDispatched(SetUserOnline),
                    filter(({ uuid }: SetUserOnline) => Boolean(uuid)),
                    tap((action: SetUserOnline) => this.socketActivator$.next(action)),
                ),
            this.actions$
                .pipe(
                    ofActionDispatched(SetUserOffline),
                    filter(({ uuid }: SetUserOffline) => Boolean(uuid)),
                    tap((action: SetUserOffline) => this.socketActivator$.next(action)),
                ),
            this.socketActivator$.asObservable()
                .pipe(
                    filter(Boolean),
                    distinctUntilChanged(isEqual),
                    debounceTime(1000),
                    withLatestFrom(this.appSettings$),
                    tap(([value, appSettings]: [any, Partial<MobileApplicationConfig>]) => {
                        if (appSettings?.MESSAGING?.isActive) {
                            return;
                        }

                        if (value.app) {
                            return this.setUserOnline(value);
                        }

                        this.setUserOffline(value);
                    }),
                ),
        );
    }

    private setUserOnline({ uuid, name, app }: SetUserOnline): void {
        this.socket.connect();
        this.socket.on('connect', () => {
            this.socket.emit('set-online', { uuid, name, app });
        });
        this.socket.on('rooms', this.setRooms);
        this.socket.on('room', this.setRoom);
        this.socket.on('messages', this.setMessages);
        this.socket.on('message', this.addMessage);
        this.socket.on('update-message', this.updateMessage);
        this.socket.on('user-online', ({ uuid: id }) => id !== uuid ? this.userOnline(id) : void (0));
        this.socket.on('user-offline', ({ uuid: id }) => id !== uuid ? this.userOffline(id) : void (0));
    }

    private setUserOffline({ uuid }: SetUserOffline): void {
        this.socket.emit('set-offline', { uuid });
        this.socket.removeAllListeners();
        this.socket.disconnect();
    }

    private setServerActiveApplication$(application: ApplicationsEnum): Observable<any> {
        this.gotoAppPage(application);

        return this.http.post(`${this.environment.apiURL}/v2/auth/set-active-app`, { application })
            .pipe(take(1));
    }

    @Dispatch()
    public gotoAppPage(app: ApplicationsEnum): Navigate {
        return new Navigate(['/', AppPathsDictionary[app]]);
    }

    @Dispatch()
    private setRooms(rooms: Array<Room>): SetRooms {
        return new SetRooms(rooms);
    }

    @Dispatch()
    private setRoom(room: Room): SetRoom {
        return new SetRoom(room);
    }

    @Dispatch()
    private setMessages(messages: Array<Message>): SetMessages {
        return new SetMessages(messages);
    }

    @Dispatch()
    private addMessage(message: Message): AddMessage {
        return new AddMessage(message);
    }

    @Dispatch()
    private updateMessage(message: Message): UpdateMessage {
        return new UpdateMessage(message);
    }

    @Dispatch()
    private userOnline(uuid: string): UserOnline {
        return new UserOnline(uuid);
    }

    @Dispatch()
    private userOffline(uuid: string): UserOffline {
        return new UserOffline(uuid);
    }
}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import versionConfig from '../version.json';

export const environment = {
    production: false,
    apiURL: 'https://bots.activeassociate.com/mobile-dev',
    botURL: 'wss://bots.activeassociate.com/dev/webchat001/',
    firebase: {
        apiKey: 'AIzaSyA8wgxL94kracI52IealT5EyXlEGwt6FPA',
        authDomain: 'actassa-op.firebaseapp.com',
        databaseURL: 'https://actassa-op.firebaseio.com',
        projectId: 'actassa-op',
        storageBucket: 'actassa-op.appspot.com',
        messagingSenderId: '378765099015',
        appId: '1:378765099015:web:4a46af76cdbd8747b82aa2',
        measurementId: 'G-FJ6BVKZ8XG'
    },
    socketIoConfig: {
        url: 'https://bots.activeassociate.com:3006',
        options: {
            autoConnect: false,
        }
    },
    appVersion: versionConfig.version,
    appId: 'com.actassa.outperform',
    appleAppId: '1543990033',
    appName: 'Outperform',
    platform: 'web'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

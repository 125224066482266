import { FcmMessageDataInterface, STORE_WRAPPER_TOKEN } from '@actassa/api';
import { Inject, Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select } from '@ngxs/store';
import { isEmpty, isEqual } from 'lodash-es';
import { combineLatest, Observable, zip } from 'rxjs';
import { take, finalize, tap, mapTo, filter, switchMap, map, distinctUntilChanged } from 'rxjs/operators';
import { PickJob } from '../+state/app-state/actions/pick-job';
import { PickPlacement } from '../+state/app-state/actions/pick-placement';
import { PickShift } from '../+state/app-state/actions/pick-shift';
import { JobsPlacementsState } from '../+state/app-state/app.state';
import { MODULE_ROOT_PATH } from '../constants/routing.constants';
import { RoutesDictionary } from '../dictionaries/routes.dictionary';
import { FCMPushDataActions } from '../enums/fcm-push-data-actions.enum';
// import { GeofenceService } from 'services/geofence.service';
import { JobInterface } from '../interfaces/job.interface';
import { PlacementInterface } from '../interfaces/placement.interface';
import { ShiftInterface } from '../interfaces/shift.interface';
import { JobsService } from './jobs.service';
import { PlacementsService } from './placements.service';
import { ShiftsService } from './shifts.service';

type pushHelper = (data: FcmMessageDataInterface) => void;

@Injectable()
export class PushHandlerService {
    @Select(JobsPlacementsState.jobs$) public jobs$: Observable<Array<JobInterface>>;
    @Select(JobsPlacementsState.placements$) public placements$: Observable<Array<PlacementInterface>>;
    @Select(JobsPlacementsState.shifts$) public shifts$: Observable<Array<ShiftInterface>>;

    private generatePushHelper: Record<FCMPushDataActions, pushHelper> = {
        [FCMPushDataActions.NEW_JOB]: this.navigateToJob,
        [FCMPushDataActions.NEW_PLACEMENT]: this.navigateToPlacement,
        [FCMPushDataActions.NEW_SHIFT]: this.navigateToShift,
        [FCMPushDataActions.REPORT_REMINDER]: this.reportReminder,
    };

    constructor(
        @Inject(STORE_WRAPPER_TOKEN) private storeWrapper,
        private readonly jobsService: JobsService,
        private readonly shiftsService: ShiftsService,
        private readonly navigationController: NavController,
        private readonly placementsService: PlacementsService,
    ) {
        this.init().subscribe();
    }

    public init(): Observable<void> {
        return combineLatest(
            this.storeWrapper.notificationData$,
            this.storeWrapper.isAppStateActive$,
        )
            .pipe(
                filter(([data, isAppActive]: [FcmMessageDataInterface, boolean]) => !isEmpty(data) && isAppActive),
                map(([data]: [FcmMessageDataInterface, boolean]) => data),
                distinctUntilChanged(isEqual),
                switchMap((data: FcmMessageDataInterface) => zip(
                    this.jobsService.load(),
                    this.placementsService.load(),
                    this.shiftsService.load(),
                ).pipe(
                    tap(() => this.handle(data)),
                    mapTo(undefined),
                )),
            );
    }

    private handle(data: FcmMessageDataInterface) {
        const handlerFunction: pushHelper = this.generatePushHelper[data.action];

        if (!handlerFunction) {
            this.storeWrapper.loadingEnd();

            return;
        }

        this.storeWrapper.clearNotificationData();

        return handlerFunction.call(this, data);
    }

    private navigateToJob({ id }: FcmMessageDataInterface): void {
        this.jobs$
            .pipe(take(1))
            .subscribe((jobs: Array<JobInterface>) => {
                const job = jobs.find((j: JobInterface) => j.id === +id);

                if (!job) {
                    this.gotoAppPage(RoutesDictionary.OPPORTUNITIES);

                    return;
                }

                this.pickJob(job);
                this.storeWrapper.loadingEnd();
                this.gotoAppPage(RoutesDictionary.JOB);
            });
    }

    private navigateToShift({ id }: FcmMessageDataInterface): void {
        this.shifts$
            .pipe(take(1))
            .subscribe((shifts: Array<ShiftInterface>) => {
                const shift = shifts.find((j: ShiftInterface) => j.id === +id);

                if (!shift) {
                    this.gotoAppPage(RoutesDictionary.OPPORTUNITIES);

                    return;
                }

                this.pickShift(shift);
                this.storeWrapper.loadingEnd();
                this.gotoAppPage(RoutesDictionary.SHIFT);
            });
    }

    private navigateToPlacement({ id }: FcmMessageDataInterface): void {
        this.placements$
            .pipe(take(1))
            .subscribe((placements: Array<PlacementInterface>) => {
                const placement = placements.find((p: PlacementInterface) => p.placementId === +id);

                if (!placement) {
                    this.gotoAppPage(RoutesDictionary.ASSIGNMENTS);

                    return;
                }

                this.pickPlacement(placement);
                this.storeWrapper.loadingEnd();
                this.gotoAppPage(RoutesDictionary.PLACEMENT);
            });
    }

    private reportReminder({ placementId }: FcmMessageDataInterface): void {
        this.placements$
            .pipe(take(1))
            .subscribe((placements: Array<PlacementInterface>) => {
                const placement = placements.find((p: PlacementInterface) => p.placementId === +placementId);

                if (!placement) {
                    this.gotoAppPage(RoutesDictionary.ASSIGNMENTS);

                    return;
                }

                this.pickPlacement(placement);
                this.storeWrapper.loadingEnd();
                this.gotoAppPage(RoutesDictionary.PLACEMENT);

                // TODO: Необходима переадресация на Placement
                //this.navigationController.navigateForward(RoutesDictionary.TIMESHEET_RECORD_TIME_SELECTED);
            });
    }

    @Dispatch()
    private pickJob(job: JobInterface | null): PickJob {
        return new PickJob(job);
    }

    @Dispatch()
    private pickShift(shift: ShiftInterface | null): PickShift {
        return new PickShift(shift);
    }

    @Dispatch()
    private pickPlacement(placement: PlacementInterface | null): PickPlacement {
        return new PickPlacement(placement);
    }

    private gotoAppPage(path: string): void {
        this.storeWrapper.baseUrl$
            .pipe(take(1))
            .subscribe((baseUrl: string) => {
                this.navigationController.navigateRoot([baseUrl, MODULE_ROOT_PATH, path]);
            });
    }
}

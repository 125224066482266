import { AppPathsDictionary } from '@actassa/api';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RootState } from 'state/root-state/root.state';
import { Store } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Navigate } from '@ngxs/router-plugin';
import { RoutesDictionary } from 'dictionaries/routes.dictionary';

@Injectable({
    providedIn: 'root'
})
export class AppAccessGuard implements CanActivate {
    constructor(private store: Store) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const apps = this.store.selectSnapshot(RootState.apps$);
        const check = !!apps.find((key: string) => AppPathsDictionary[key] === next.routeConfig.path);

        if (check) {
            return true;
        }

        this.gotoLogout();

        return false;
    }

    @Dispatch()
    private gotoLogout(): Navigate {
        return new Navigate([RoutesDictionary.LOGOUT]);
    }
}

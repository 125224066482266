import { Environment, ENVIRONMENT_TOKEN, MobileApplicationConfig } from '@actassa/api';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

interface ResponseDTO {
    status: string,
    message: string,
    data: Partial<MobileApplicationConfig>,
};

@Injectable()
export class AppSettingsService {
    constructor(
        @Inject(ENVIRONMENT_TOKEN) private readonly environment: Environment,
        private readonly http: HttpClient,
    ) { }
    
    public getSettings$(): Observable<Partial<MobileApplicationConfig>> {
        return this.http.get<ResponseDTO>(`${this.environment.apiURL}/settings`)
            .pipe(
                map(({ data }) => data),
                catchError(() => of({})),
            );
    }
}

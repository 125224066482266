import { ApplicationsEnum } from '../enums/applications.enum';

export const AppPathsDictionary: Record<ApplicationsEnum, string> = {
    [ApplicationsEnum.CANDIDATE]: 'shell',
    [ApplicationsEnum.CANDIDATE_ALL]: 'hmvuotkgyasx',
    [ApplicationsEnum.CANDIDATE_ENTERPRISE]: 'esyq0mbkavl8',
    [ApplicationsEnum.CANDIDATE_NSWBC]: '1d3jnkfertfp9',
    [ApplicationsEnum.CANDIDATE_ONESTAFF]: '1ajme4yxtfgh',
    [ApplicationsEnum.CANDIDATE_SYMMETRY]: 'avl8z7oajpme4',
    [ApplicationsEnum.CANDIDATE_TRN]: 'utcobgk49xfl',
    [ApplicationsEnum.CANDIDATE_ELE]: 'pp8zl0esihmw',
    [ApplicationsEnum.CLIENT]: 'shell',
    [ApplicationsEnum.CLIENT_TRN]: 'fp925x1d3uotk',
    [ApplicationsEnum.CLIENT_ELE]: 'vjqiuujmybrc',
    [ApplicationsEnum.CONSULTANT]: 'shell',
    [ApplicationsEnum.CONSULTANT_ALL]: 'pjiyqwklnahv',
    [ApplicationsEnum.CONSULTANT_ENTERPRISE]: 'jq4tfp925x1g',
    [ApplicationsEnum.CONSULTANT_NSWBC]: 'o5w1gk8sypl78',
    [ApplicationsEnum.CONSULTANT_ONESTAFF]: 'lnfz27ok6ix0',
    [ApplicationsEnum.CONSULTANT_SYMMETRY]: 'e4uxobgk8syq0',
    [ApplicationsEnum.CONSULTANT_TRN]: '9ho5w1d3jnkf',
    [ApplicationsEnum.CONSULTANT_ELE]: 'eklmkwipvysa',
};

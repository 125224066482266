import { STORE_WRAPPER_TOKEN, UserInterface } from '@actassa/api';
import { Inject, Injectable } from '@angular/core';
import { State, Selector, Action, StateContext } from '@ngxs/store';
import { cloneDeep } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { switchMap, map, take } from 'rxjs/operators';
import { UserViewStatus } from '../../enums/user-view-status.enum';
import { JobInterface } from '../../interfaces/job.interface';
import { JobsPlacementsInterface } from '../../interfaces/jobs-placements-state.interface';
import { PlacementInterface } from '../../interfaces/placement.interface';
import { ReportInterface } from '../../interfaces/report.interface';
import { ShiftInterface } from '../../interfaces/shift.interface';
import { ChangeJobStatus } from './actions/change-job-status';
import { ChangePlacementStatus } from './actions/change-placement-status';
import { ChangeShiftStatus } from './actions/change-shift-status';
import { ClearAwaitingJobStatus } from './actions/clear-awaiting-job-status';
import { ClearAwaitingShiftStatus } from './actions/clear-awaiting-shift-status';
import { ClearAwaitingShiftDataReport } from './actions/clear-awaiting-shiftdata-report';
import { ClearJobsLoadingStatus } from './actions/clear-jobs-loading-status';
import { ClearPlacementsLoadingStatus } from './actions/clear-placements-loading-status';
// import { LoadReportsFailure } from './actions/load-reports-failure';
import { ClearShiftsLoadingStatus } from './actions/clear-shifts-loading-status';
import { LoadReportsSuccess } from './actions/load-reports-success';
// import { Logout } from 'state/root-state/actions/logout';
import { PickJob } from './actions/pick-job';
import { PickPlacement } from './actions/pick-placement';
import { PickReport } from './actions/pick-report';
import { PickShift } from './actions/pick-shift';
import { ResetState } from './actions/reset-state';
import { SendReport } from './actions/send-report';
import { SetAwaitingJobStatus } from './actions/set-awaiting-job-status';
import { SetAwaitingShiftStatus } from './actions/set-awaiting-shift-status';
import { SetAwaitingShiftDataReport } from './actions/set-awaiting-shiftdata-report';
import { SetJobUserViewStatus } from './actions/set-job-user-view-status';
import { SetJobsLoadingStatus } from './actions/set-jobs-loading-status';
import { SetPlacementUserViewStatus } from './actions/set-placement-user-view-status';
import { SetPlacementsLoadingStatus } from './actions/set-placements-loading-status';
import { SetReport } from './actions/set-report';
import { SetShiftUserViewStatus } from './actions/set-shift-user-view-status';
import { SetShiftsLoadingStatus } from './actions/set-shifts-loading-status';
import { SubmitReport } from './actions/submit-report';
import { UpdateJobs } from './actions/update-jobs';
import { UpdatePlacements } from './actions/update-placements';
import { UpdateReport } from './actions/update-report';
import { UpdateShifts } from './actions/update-shifts';

const DEFAULT_STATE_DATA: JobsPlacementsInterface = {
    awaitingJobStatusChanges: [],
    awaitingShiftStatusChanges: [],
    awaitingShiftDataReports: [],
    isJobsLoading: false,
    isPlacementsLoading: false,
    isShiftsLoading: false,
    job: null,
    jobs: [],
    placement: null,
    placements: [],
    report: null,
    reports: [],
    shift: null,
    shifts: [],
};

@State<JobsPlacementsInterface>({
    name: 'jobsplacements',
    defaults: DEFAULT_STATE_DATA,
})
@Injectable()
export class JobsPlacementsState {
    constructor(
        @Inject(STORE_WRAPPER_TOKEN) private storeWrapper,
    ) { }

    @Selector()
    public static jobs$(state: JobsPlacementsInterface): Array<JobInterface> {
        return state.jobs;
    }

    @Selector()
    public static shifts$(state: JobsPlacementsInterface): Array<ShiftInterface> {
        return state.shifts;
    }

    @Selector()
    public static placements$(state: JobsPlacementsInterface): Array<PlacementInterface> {
        return state.placements;
    }

    @Selector()
    public static job$(state: JobsPlacementsInterface): JobInterface | null {
        return state.job;
    }

    @Selector()
    public static shift$(state: JobsPlacementsInterface): ShiftInterface | null {
        return state.shift;
    }

    @Selector()
    public static placement$(state: JobsPlacementsInterface): PlacementInterface | null {
        return state.placement;
    }

    @Selector()
    public static reports$(state: JobsPlacementsInterface): Array<ReportInterface> | null {
        return state.reports;
    }

    @Selector()
    public static report$(state: JobsPlacementsInterface): ReportInterface | null {
        return state.report;
    }

    @Selector()
    public static awaitingJobStatusChanges$(state: JobsPlacementsInterface): Array<any> | null {
        return state.awaitingJobStatusChanges;
    }

    @Selector()
    public static awaitingShiftStatusChanges$(state: JobsPlacementsInterface): Array<any> | null {
        return state.awaitingShiftStatusChanges;
    }

    @Selector()
    public static awaitingShiftDataReports$(state: JobsPlacementsInterface): Array<any> | null {
        return state.awaitingShiftDataReports;
    }

    @Selector()
    public static isJobsLoading$(state: JobsPlacementsInterface): boolean {
        return state.isJobsLoading;
    }

    @Selector()
    public static isShiftsLoading$(state: JobsPlacementsInterface): boolean {
        return state.isShiftsLoading;
    }

    @Selector()
    public static isPlacementsLoading$(state: JobsPlacementsInterface): boolean {
        return state.isPlacementsLoading;
    }

    @Action(UpdateJobs)
    public updateJobs(stateContext: StateContext<JobsPlacementsInterface>, action: UpdateJobs): void {
        stateContext.patchState({
            jobs: [...action.jobs],
        });
    }

    @Action(UpdateShifts)
    public updateShifts(stateContext: StateContext<JobsPlacementsInterface>, { shifts }: UpdateShifts): void {
        stateContext.patchState({
            shifts: [...shifts],
        });
    }

    @Action(ChangeJobStatus)
    public changeJobStatus$(stateContext: StateContext<JobsPlacementsInterface>, action: ChangeJobStatus): Observable<void> {
        const { id, status } = action;
        const jobs = cloneDeep(stateContext.getState().jobs);
        const job = jobs.find(j => j.id === id);

        if (job) {
            job.status = status;

            return stateContext.dispatch(new UpdateJobs(jobs));
        }

        return of();
    }

    @Action(ChangeShiftStatus)
    public changeShiftStatus$(stateContext: StateContext<JobsPlacementsInterface>, action: ChangeShiftStatus): Observable<void> {
        const { id, status } = action;
        const shifts = cloneDeep(stateContext.getState().shifts);
        const shift = shifts.find(j => j.id === id);

        if (shift) {
            shift.status = status;

            return stateContext.dispatch(new UpdateShifts(shifts));
        }

        return of();
    }

    @Action(ChangePlacementStatus)
    public changePlacementStatus$(stateContext: StateContext<JobsPlacementsInterface>, action: ChangePlacementStatus): Observable<void> {
        const { id, status } = action;
        const placements = cloneDeep(stateContext.getState().placements);
        const placement = placements.find(p => p.placementId === id);

        if (placement) {
            placement.status = status;

            return stateContext.dispatch(new UpdatePlacements(placements));
        }

        return of();
    }

    @Action(UpdatePlacements)
    public updatePlacements(stateContext: StateContext<JobsPlacementsInterface>, action: UpdatePlacements): void {
        stateContext.patchState({
            placements: [...action.placements],
        });
    }

    @Action(ResetState)
    public resetState(stateContext: StateContext<JobsPlacementsInterface>): void {
        stateContext.setState(DEFAULT_STATE_DATA);
    }

    // @Action(Logout)
    // public logout(stateContext: StateContext<JobsPlacementsInterface>): void {
    //     stateContext.setState(DEFAULT_STATE_DATA);
    // }

    @Action(PickJob)
    public pickJob(stateContext: StateContext<JobsPlacementsInterface>, action: PickJob): void {
        const { job } = action;

        stateContext.patchState({ job });
    }

    @Action(PickShift)
    public pickShift(stateContext: StateContext<JobsPlacementsInterface>, action: PickShift): void {
        const { shift } = action;

        stateContext.patchState({ shift });
    }

    @Action(PickPlacement)
    public pickPlacement(stateContext: StateContext<JobsPlacementsInterface>, action: PickPlacement): void {
        const { placement } = action;

        stateContext.patchState({ placement });
    }

    @Action(PickReport)
    public pickReport(stateContext: StateContext<JobsPlacementsInterface>, action: PickReport): void {
        const { report } = action;

        stateContext.patchState({ report });
    }

    @Action(SubmitReport)
    public submitReport(stateContext: StateContext<JobsPlacementsInterface>, action: SubmitReport): Observable<unknown> {
        const ActiveReport = { ...action.report };
        const { reports, placement } = stateContext.getState();
        const savedReport = reports.find(r => r.id === ActiveReport.id);

        return this.storeWrapper.user$
            .pipe(
                take(1),
                map((user: UserInterface) => ({
                    ...savedReport,
                    ...ActiveReport,
                    ApplicantId: user.id,
                    providerID: placement ? placement.providerID : '',
                })),
                switchMap((report: ReportInterface) => stateContext.dispatch([new SetReport(report), new SendReport(report)])),
            );
    }

    @Action(SetAwaitingJobStatus)
    public setAwaitingJobStatus(stateContext: StateContext<JobsPlacementsInterface>, action: SetAwaitingJobStatus): void {
        const { data } = action;
        const current = stateContext.getState().awaitingJobStatusChanges.filter(a => a.jobID !== data.jobID);

        stateContext.patchState({
            awaitingJobStatusChanges: [...current, data],
        });
    }

    @Action(SetAwaitingShiftStatus)
    public setAwaitingShiftStatus(stateContext: StateContext<JobsPlacementsInterface>, action: SetAwaitingShiftStatus): void {
        const { data } = action;
        const current = stateContext.getState().awaitingShiftStatusChanges.filter(a => a.shiftId !== data.shiftId);

        stateContext.patchState({
            awaitingShiftStatusChanges: [...current, data],
        });
    }

    @Action(SetReport)
    public setReport(stateContext: StateContext<JobsPlacementsInterface>, action: SetReport): void {
        const { report } = action;
        const reports = stateContext.getState().reports.filter(r => r.id !== report.id);

        stateContext.patchState({
            reports: [
                ...reports,
                report,
            ],
        });
    }

    @Action(UpdateReport)
    public updateReport(stateContext: StateContext<JobsPlacementsInterface>, action: UpdateReport): Observable<void> {
        const ActiveReport = { ...action.report };
        const { reports, placement } = stateContext.getState();
        const savedReport = reports.find(r => r.id === ActiveReport.id);

        return this.storeWrapper.user$
            .pipe(
                map((user: UserInterface) => ({
                    ...savedReport,
                    ...ActiveReport,
                    ApplicantId: user.id,
                    providerID: placement ? placement.providerID : '',
                })),
                switchMap((report: ReportInterface) => stateContext.dispatch(new SetReport(report))),
            );
    }

    @Action(SetAwaitingShiftDataReport)
    public setAwaitingReport(stateContext: StateContext<JobsPlacementsInterface>, action: SetAwaitingShiftDataReport): void {
        const { report } = action;
        const reports = stateContext.getState().awaitingShiftDataReports.filter(r => r.id !== report.id);

        stateContext.patchState({
            awaitingShiftDataReports: [
                ...reports,
                report,
            ],
        });
    }

    @Action(ClearAwaitingShiftDataReport)
    public clearAwaitingShiftDataReport(stateContext: StateContext<JobsPlacementsInterface>, action: ClearAwaitingShiftDataReport): void {
        const { report } = action;
        const reports = stateContext.getState().awaitingShiftDataReports.filter(r => r.id !== report.id);

        stateContext.patchState({
            awaitingShiftDataReports: [
                ...reports,
            ],
        });
    }

    @Action(ClearAwaitingJobStatus)
    public clearAwaitingJobStatus(stateContext: StateContext<JobsPlacementsInterface>, action: ClearAwaitingJobStatus): void {
        const { data } = action;
        const statuses = stateContext.getState().awaitingJobStatusChanges.filter(s => s.jobID !== data.jobID);

        stateContext.patchState({
            awaitingJobStatusChanges: [
                ...statuses,
            ],
        });
    }

    @Action(ClearAwaitingShiftStatus)
    public clearAwaitingShiftStatus(stateContext: StateContext<JobsPlacementsInterface>, action: ClearAwaitingShiftStatus): void {
        const { data } = action;
        const statuses = stateContext.getState().awaitingShiftStatusChanges.filter(s => s.shiftId !== data.shiftId);

        stateContext.patchState({
            awaitingShiftStatusChanges: [
                ...statuses,
            ],
        });
    }

    @Action(SetJobsLoadingStatus)
    public setJobsLoadingStatus(stateContext: StateContext<JobsPlacementsInterface>): void {
        stateContext.patchState({ isJobsLoading: true });
    }

    @Action(SetShiftsLoadingStatus)
    public setShiftsLoadingStatus(stateContext: StateContext<JobsPlacementsInterface>): void {
        stateContext.patchState({ isShiftsLoading: true });
    }

    @Action(ClearJobsLoadingStatus)
    public clearJobsLoadingStatus(stateContext: StateContext<JobsPlacementsInterface>): void {
        stateContext.patchState({ isJobsLoading: false });
    }

    @Action(ClearShiftsLoadingStatus)
    public clearShiftsLoadingStatus(stateContext: StateContext<JobsPlacementsInterface>): void {
        stateContext.patchState({ isShiftsLoading: false });
    }

    @Action(SetPlacementsLoadingStatus)
    public setPlacementsLoadingStatus(stateContext: StateContext<JobsPlacementsInterface>): void {
        stateContext.patchState({ isPlacementsLoading: true });
    }

    @Action(ClearPlacementsLoadingStatus)
    public clearPlacementsLoadingStatus(stateContext: StateContext<JobsPlacementsInterface>): void {
        stateContext.patchState({ isPlacementsLoading: false });
    }

    @Action(LoadReportsSuccess)
    public loadReportsSuccess(stateContext: StateContext<JobsPlacementsInterface>, action: LoadReportsSuccess): void {
        const { reports } = action;
        // AMP-308
        // const oldCurrentReport = { ...stateContext.getState().report };
        // const newCurrentReport = reports.find(report => report.id === oldCurrentReport.id);

        stateContext.patchState({
            reports: [...reports],
            // AMP-308
            // report: {
            //     ...oldCurrentReport,
            //     ...newCurrentReport,
            // },
        });
    }

    @Action(SetJobUserViewStatus)
    public setJobUserViewStatus(stateContext: StateContext<JobsPlacementsInterface>, { jobId }: SetJobUserViewStatus): void {
        const selectedJob = stateContext.getState().jobs.filter(item => item.id === jobId)[0];
        const jobs = stateContext.getState().jobs.filter(item => item.id !== jobId);
        const job = {
            ...selectedJob,
            userViewStatus: UserViewStatus.READ,
        };

        stateContext.patchState({
            job,
            jobs: [
                ...jobs,
                job,
            ],
        });
    }

    @Action(SetShiftUserViewStatus)
    public setShiftUserViewStatus(stateContext: StateContext<JobsPlacementsInterface>, { shiftId }: SetShiftUserViewStatus): void {
        const selectedShift = stateContext.getState().shifts.filter(item => item.id === shiftId)[0];
        const shifts = stateContext.getState().shifts.filter(item => item.id !== shiftId);
        const shift = {
            ...selectedShift,
            userViewStatus: UserViewStatus.READ,
        };

        stateContext.patchState({
            shift,
            shifts: [
                ...shifts,
                shift,
            ],
        });
    }

    @Action(SetPlacementUserViewStatus)
    public setPlacementUserViewStatus(
        stateContext: StateContext<JobsPlacementsInterface>,
        { placementId }: SetPlacementUserViewStatus,
    ): void {
        const selectedPlacement = stateContext.getState().placements.filter(item => item.placementId === placementId)[0];
        const placements = stateContext.getState().placements.filter(item => item.placementId !== placementId);
        const placement = {
            ...selectedPlacement,
            userViewStatus: UserViewStatus.READ,
        };

        stateContext.patchState({
            placement,
            placements: [
                ...placements,
                placement,
            ],
        });
    }

    // @Action(LoadReportsFailure)
    // public loadReportsFailure(stateContext: StateContext<JobsPlacementsInterface>): void {

    // }
}

import {
    Component,
    ChangeDetectionStrategy,
} from '@angular/core';

@Component({
    selector: 'actassa-jobs-placements',
    templateUrl: 'jobs-placements.component.html',
    styleUrls: ['jobs-placements.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobsPlacementsComponent {}

import { SendDeviceTokenInterface } from '@actassa/api';
import { NetworkStatusService } from '@actassa/shared';
import { Injectable } from '@angular/core';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { SendDeviceToken } from 'state/root-state/actions/send-device-token';
import { RootState } from 'state/root-state/root.state';

@Injectable({
    providedIn: 'root',
})
export class SchedulerService {
    @Select(RootState.awaitingDeviceToken$) public awaitingDeviceTokenData$: Observable<SendDeviceTokenInterface | null>;

    constructor(
        private readonly networkStatusService: NetworkStatusService,
    ) { }

    public sendAwaitingDeviceToken$(): Observable<any> {
        return this.networkStatusService.isNetworkConnected$
            .pipe(
                filter((isConnected: boolean) => isConnected),
                switchMap(() => this.awaitingDeviceTokenData$.pipe(take(1))),
                filter(data => !!data),
                tap((data: SendDeviceTokenInterface) => this.sendDeviceToken(data)),
            );
    }

    @Dispatch()
    private sendDeviceToken(data: SendDeviceTokenInterface): SendDeviceToken {
        return new SendDeviceToken(data);
    }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BreakLinePipe } from './break-line.pipe';

@NgModule({
    declarations: [BreakLinePipe],
    exports: [BreakLinePipe],
    imports: [CommonModule],
})
export class BreakLineModule { }

import { JobsPlacementsState } from '../+state/app-state/app.state';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { filter, switchMap, map, concatAll, take } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { merge, Observable, of } from 'rxjs';
import { isEmpty } from 'lodash-es';
import { JobsService } from '../services/jobs.service';
import { LoadReportsEvent } from '../+state/app-state/actions/load-reports-event';
import { ReportsService } from '../services/reports.service';
import { Select } from '@ngxs/store';
import { LoadJobsEvent } from '../+state/app-state/actions/load-jobs-event';
import { LoadPlacementsEvent } from '../+state/app-state/actions/load-placements-event';
import { STORE_WRAPPER_TOKEN } from '@actassa/api';
import { ReportInterface } from '../interfaces/report.interface';
import { JobChangeStatusInterface } from '../interfaces/job-change-status.interface';
import { LoadShiftsEvent } from '../+state/app-state/actions/load-shifts-event';
import { ShiftChangeStatusInterface } from '../interfaces/shift-change-status.interface';
import { ShiftsService } from './shifts.service';

@Injectable({
    providedIn: 'root',
  })
export class SchedulerService {
    @Select(JobsPlacementsState.awaitingJobStatusChanges$) public jobStatuses$: Observable<any[]>;
    @Select(JobsPlacementsState.awaitingShiftStatusChanges$) public shiftStatuses$: Observable<any[]>;
    @Select(JobsPlacementsState.awaitingShiftDataReports$) public shiftDataReports$: Observable<any[]>;

    constructor(
        @Inject(STORE_WRAPPER_TOKEN) private storeWrapper,
        private readonly jobsService: JobsService,
        private readonly shiftsService: ShiftsService,
        private readonly reportsService: ReportsService,
    ) {
        console.log('SchedulerService');
        this.init().subscribe();
        this.preloadData();
    }

    public init(): Observable<void> {
        return merge(
            // interval(60000).pipe(tap(() => this.loadReports())),
            this.sendAwaitingShiftDataReports$(),
            this.sendAwaitingJobStatuses$(),
            this.sendAwaitingShiftStatuses$(),
        );
    }

    @Dispatch()
    public preloadData(): (LoadJobsEvent | LoadPlacementsEvent | LoadReportsEvent | LoadShiftsEvent)[] {
        return [
            new LoadJobsEvent(),
            new LoadShiftsEvent(),
            new LoadPlacementsEvent(),
            new LoadReportsEvent(),
        ];
    }

    private sendAwaitingJobStatuses$(): Observable<any> {
        return this.storeWrapper.isNetworkConnected$
            .pipe(
                filter((isConnected: boolean) => isConnected),
                switchMap(() => this.jobStatuses$.pipe(take(1))),
                filter(statuses => !isEmpty(statuses)),
                switchMap((statuses: JobChangeStatusInterface[]) => of(...statuses)),
                map((status: JobChangeStatusInterface) => this.jobsService.sendJobStatusToServer$(status)),
                concatAll(),
            );
    }

    private sendAwaitingShiftStatuses$(): Observable<any> {
        return this.storeWrapper.isNetworkConnected$
            .pipe(
                filter((isConnected: boolean) => isConnected),
                switchMap(() => this.shiftStatuses$.pipe(take(1))),
                filter(statuses => !isEmpty(statuses)),
                switchMap((statuses: ShiftChangeStatusInterface[]) => of(...statuses)),
                map((status: ShiftChangeStatusInterface) => this.shiftsService.sendShiftStatusToServer$(status)),
                concatAll(),
            );
    }

    private sendAwaitingShiftDataReports$(): Observable<any> {
        return this.storeWrapper.isNetworkConnected$
            .pipe(
                filter((isConnected: boolean) => isConnected),
                switchMap(() => this.shiftDataReports$.pipe(take(1))),
                filter(reports => !isEmpty(reports)),
                switchMap((reports: ReportInterface[]) => of(...reports)),
                map((report: ReportInterface) => this.reportsService.sendReportToServer$(report)),
                concatAll(),
            );
    }
}

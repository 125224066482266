import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Injectable()
export class NavigationService {
    private history: Array<string> = []

    constructor(private router: Router) { }

    public startSaveHistory(): void {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.history.push(event.urlAfterRedirects);
            }
        });
    }

    public getHistory(): Array<string> {
        return this.history;
    }

    public goBack(defaultUrl?: string): void {
        this.history.pop();

        if (this.history.length > 0) {
            this.router.navigate([this.getPreviousUrl()]);
        } else {
            this.router.navigateByUrl(defaultUrl || '/');
        }
    }

    public getPreviousUrl(): string {
        if (this.history.length > 0) {
            return this.history[this.history.length - 1];
        }

        return '';
    }
}
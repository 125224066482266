import { Injectable, QueryList } from '@angular/core';
import { Router } from '@angular/router';
import {
    IonRouterOutlet,
    Platform,
    PopoverController,
    MenuController,
    ModalController,
    ActionSheetController,
} from '@ionic/angular';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { RoutesDictionary } from 'dictionaries/routes.dictionary';
import { ShowToast } from 'state/root-state/actions/show-toast';

@Injectable({
    providedIn: 'root',
})
export class BackButtonService {
    private lastTimeBackPress = 0;
    private timePeriodToExit = 2000;
    private exitURLs = [RoutesDictionary.LOGOUT];

    constructor(
        private readonly actionSheetCtrl: ActionSheetController,
        private readonly menu: MenuController,
        private readonly modalCtrl: ModalController,
        private readonly platform: Platform,
        private readonly popoverCtrl: PopoverController,
        private readonly router: Router,
    ) { }

    public backButtonEvent(routerOutlets: QueryList<IonRouterOutlet>): void {
        this.platform.backButton.subscribe(async () => {
            // close action sheet
            try {
                const element = await this.actionSheetCtrl.getTop();

                if (element) {
                    element.dismiss();
                    return;
                }
            } catch (error) { }

            // close popover
            try {
                const element = await this.popoverCtrl.getTop();

                if (element) {
                    element.dismiss();
                    return;
                }
            } catch (error) { }

            // close modal
            try {
                const element = await this.modalCtrl.getTop();

                if (element) {
                    element.dismiss();
                    return;
                }
            } catch (error) {
                console.log(error);
            }

            // close side menua
            try {
                const element = await this.menu.getOpen();

                if (element) {
                    this.menu.close();
                    return;
                }
            } catch (error) { }

            routerOutlets.forEach((outlet: IonRouterOutlet) => {
                if (outlet && outlet.canGoBack()) {
                    outlet.pop();

                    return;
                }

                if (
                    this.exitURLs.includes(this.router.url.replace(/^\//, ''))
                ) {
                    if (
                        new Date().getTime() - this.lastTimeBackPress <
                        this.timePeriodToExit
                    ) {
                        // eslint-disable-next-line @typescript-eslint/dot-notation
                        navigator['app'].exitApp();

                        return;
                    }

                    this.presentToast('Press back again to exit App.');
                    this.lastTimeBackPress = new Date().getTime();
                }
            });
        });
    }

    @Dispatch()
    private presentToast(message: string): ShowToast {
        return new ShowToast(message);
    }
}

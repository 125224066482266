import { ApplicationsEnum, STORE_WRAPPER_TOKEN } from '@actassa/api';
import { MainMenuItemInterface } from '@actassa/api';
import { Inject, Injectable, Optional } from '@angular/core';
import { Select } from '@ngxs/store';
import { combineLatest, Observable, of } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { JobsPlacementsState } from '../+state/app-state/app.state';
import { JobStatus } from '../enums/job-status.enum';
import { PlacementStatus } from '../enums/placement-status.enum';
import { SectionsEnum } from '../enums/sections.enum';
import { UserViewStatus } from '../enums/user-view-status.enum';
import { JobInterface } from '../interfaces/job.interface';
import { PlacementInterface } from '../interfaces/placement.interface';
import { ShiftInterface } from '../interfaces/shift.interface';
import { JobsPlacementsPages } from '../main-menu.config';
import { JOBS_PLACEMENTS_TITLES_TOKEN } from '../tokens/titles.token';
import { JobsService } from './jobs.service';
import { PlacementsService } from './placements.service';
import { PushHandlerService } from './push-handler.service';
import { ReportsService } from './reports.service';
import { SchedulerService } from './scheduler.service';
import { ShiftsService } from './shifts.service';

type OfferInterface = JobInterface | ShiftInterface;

@Injectable()
export class MenuItemsService {

    @Select(JobsPlacementsState.jobs$) private jobs$: Observable<Array<JobInterface>>;
    @Select(JobsPlacementsState.shifts$) private shifts$: Observable<Array<ShiftInterface>>;
    @Select(JobsPlacementsState.placements$) private placements$: Observable<Array<PlacementInterface>>;

    private mainMenuItems$: Observable<Array<MainMenuItemInterface>>;
    private jobCurrentCount$: Observable<string>;
    private jobOpportunitiesCount$: Observable<string>;
    private placementAssignmentsCount$: Observable<string>;
    //TODO: Костыль, нужно исправить
    private itemLabel = 'items';

    constructor(
        @Inject(STORE_WRAPPER_TOKEN) private storeWrapper,
        @Optional() @Inject(JOBS_PLACEMENTS_TITLES_TOKEN) private titlesConfig: Record<SectionsEnum, string>,
        private readonly jobsService: JobsService,
        private readonly shiftsService: ShiftsService,
        private readonly placementsService: PlacementsService,
        private readonly pushHandlerService: PushHandlerService,
        private readonly reportsService: ReportsService,
        private readonly schedulerService: SchedulerService,
    ) {
        this.mainMenuItems$ = of(JobsPlacementsPages(this.titlesConfig || {}));
        this.jobCurrentCount$ = this.initCurrentJobCount$();
        this.jobOpportunitiesCount$ = this.initJobOpportunitiesCount$();
        this.placementAssignmentsCount$ = this.initPlacementAssignmentsCount$();
        this.storeWrapper.app$
            .pipe(
                take(1),
                tap((app: ApplicationsEnum) => {
                    if (app === ApplicationsEnum.CANDIDATE_NSWBC) {
                        this.itemLabel = 'shifts';
                    }
                }),
            )
            .subscribe();
    }

    public get(): Observable<Array<MainMenuItemInterface>> {
        return combineLatest([this.mainMenuItems$, this.jobCurrentCount$, this.jobOpportunitiesCount$, this.placementAssignmentsCount$])
            .pipe(
                map(this.mapSubTitles),
            );
    }

    private mapSubTitles([
        mainMenuItems,
        jobCurrentCount,
        jobOpportunitiesCount,
        placementAssignmentsCount,
    ]: [
            Array<MainMenuItemInterface>,
            string,
            string,
            string
        ]): Array<MainMenuItemInterface> {
        return mainMenuItems.map((mainMenuItem) => {
            switch (mainMenuItem.key) {
                case SectionsEnum.APPLICATIONS:
                    mainMenuItem.subTitle = jobCurrentCount;
                    break;

                case SectionsEnum.OPPORTUNITIES:
                    mainMenuItem.subTitle = jobOpportunitiesCount;
                    break;

                case SectionsEnum.ASSIGNMENTS:
                    mainMenuItem.subTitle = placementAssignmentsCount;
                    break;
            }

            return mainMenuItem;
        });
    }

    private initCurrentJobCount$(): Observable<string> {
        return combineLatest([this.jobs$, this.shifts$])
            .pipe(
                map(([jobs, shifts]: [Array<JobInterface>, Array<ShiftInterface>]) => ([...jobs, ...shifts])),
                map((items: Array<OfferInterface>) => {
                    const shiftsCount = items.filter((item: OfferInterface) => [
                        JobStatus.CURRENT_APPLICATION,
                        JobStatus.INTERESTED_SOME_SHIFTS,
                    ].includes(item.status)).length;

                    if (shiftsCount) {
                        return `${shiftsCount} ${this.itemLabel}`;
                    }

                    return '';
                }),
            );
    }

    private initJobOpportunitiesCount$(): Observable<string> {
        return combineLatest([this.jobs$, this.shifts$])
            .pipe(
                map(([jobs, shifts]: [Array<JobInterface>, Array<ShiftInterface>]) => ([...jobs, ...shifts])),
                map((items: Array<OfferInterface>) => {
                    const filteredItems = items.filter((item: OfferInterface) => [JobStatus.OPEN_OPPORTUNITY].includes(item.status));
                    const filteredItemsLength = filteredItems.length;
                    const newItemsLength = filteredItems.filter(
                        (item: OfferInterface) => item.userViewStatus === UserViewStatus.NEW,
                    ).length;
                    const updatedItemsLength = filteredItems.filter(
                        (item: OfferInterface) => item.userViewStatus === UserViewStatus.UPDATED,
                    ).length;

                    if (newItemsLength && updatedItemsLength) {
                        return `${filteredItemsLength} ${this.itemLabel} (${newItemsLength + updatedItemsLength} new/updated)`;
                    }

                    if (updatedItemsLength) {
                        return `${filteredItemsLength} ${this.itemLabel} (${updatedItemsLength} updated)`;
                    }

                    if (newItemsLength) {
                        return `${filteredItemsLength} ${this.itemLabel} (${newItemsLength} new)`;
                    }

                    if (filteredItemsLength) {
                        return `${filteredItemsLength} ${this.itemLabel}`;
                    }

                    return '';
                }),
            );
    }

    private initPlacementAssignmentsCount$(): Observable<string> {
        return this.placements$
            .pipe(
                map((placements: Array<PlacementInterface>) => {
                    const filteredPlacements = placements.filter(
                        (placement: PlacementInterface) => [PlacementStatus.PLACEMENT_CURRENT].includes(placement.status),
                    );
                    const filteredPlacementsLength = filteredPlacements.length;
                    const newPlacementsLength = filteredPlacements.filter(
                        (placement: PlacementInterface) => placement.userViewStatus === UserViewStatus.NEW,
                    ).length;
                    const updatedPlacementsLength = filteredPlacements.filter(
                        (placement: PlacementInterface) => placement.userViewStatus === UserViewStatus.UPDATED,
                    ).length;

                    if (newPlacementsLength && updatedPlacementsLength) {
                        return `${filteredPlacementsLength} ${this.itemLabel} (${newPlacementsLength + updatedPlacementsLength} new/updated)`;
                    }

                    if (updatedPlacementsLength) {
                        return `${filteredPlacementsLength} ${this.itemLabel} (${updatedPlacementsLength} new/updated)`;
                    }

                    if (newPlacementsLength) {
                        return `${filteredPlacementsLength} ${this.itemLabel} (${newPlacementsLength} new/updated)`;
                    }

                    if (filteredPlacementsLength) {
                        return `${filteredPlacementsLength} ${this.itemLabel}`;
                    }

                    return '';
                }),
            );
    }
}

import { AUTH_SERVICE_TOKEN, ENVIRONMENT_TOKEN, STORE_WRAPPER_TOKEN } from '@actassa/api';
import { jobsPlacementsAfterDeserialize } from '@actassa/jobs-placements';
import { AppSettingsService, APP_SETTINGS_TOKEN, NavigationService } from '@actassa/shared';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouteReuseStrategy } from '@angular/router';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
// import { ClarityModule } from '@clr/angular';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { NgxsDispatchPluginModule } from '@ngxs-labs/dispatch-decorator';
import { NgxsSelectSnapshotModule } from '@ngxs-labs/select-snapshot';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
// import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
// import { NgxsAsyncStoragePluginModule } from '@ngxs-labs/async-storage-plugin';
// import { StorageService } from 'services/storage.service';
// import { IonicStorageModule } from '@ionic/storage';
// import { MessagingModule } from './modules/messaging/messaging.module';
// import { AgmCoreModule } from '@agm/core';
// import { GlobalErrorHandlerService } from '@actassa/shared';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { environment } from 'environments/environment';
import { AuthInterceptor } from 'interceptors/auth.interceptor';
import { SocketIoModule } from 'ngx-socket-io';
import { AuthService } from 'services/auth.service';
import { StoreWrapperService } from 'services/store-wrapper.service';
import { RootState } from 'state/root-state/root.state';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MessagingModule } from './modules/messaging/messaging.module';
// import { IonicStorageModule } from '@ionic/storage-angular';
// import { Drivers } from '@ionic/storage';

Sentry.init({
    dsn: 'https://ac02cbdb92ae4f9293b97a6d78956cc1@o802773.ingest.sentry.io/5809158',
    release: environment.appVersion,
    environment: environment.production ? 'prod' : 'dev',
    integrations: [
        // Registers and configures the Tracing integration,
        // which automatically instruments your application to monitor its
        // performance, including custom Angular routing instrumentation
        new Integrations.BrowserTracing({
            tracingOrigins: ['http://localhost', 'https://bots.activeassociate.com/'],
            routingInstrumentation: Sentry.routingInstrumentation,
        }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

@NgModule({
    declarations: [
        AppComponent,
    ],
    entryComponents: [],
    imports: [
        BrowserModule,
        HttpClientModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        NgxsModule.forRoot([RootState], {
            developmentMode: !environment.production,
            selectorOptions: {
                suppressErrors: environment.production,
                injectContainerState: false,
            },
        }),
        NgxsDispatchPluginModule.forRoot(),
        NgxsRouterPluginModule.forRoot(),
        NgxsSelectSnapshotModule.forRoot(),
        NgxsStoragePluginModule.forRoot({
            afterDeserialize: (obj: any, key: string) => { 
                const store = jobsPlacementsAfterDeserialize(obj, key);

                return store;
            },
        }),
        // NgxsAsyncStoragePluginModule.forRoot(StorageService),
        // IonicStorageModule.forRoot({
        //     name: 'Outperform',
        //     driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
        // }),
        // ClarityModule,
        BrowserAnimationsModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireMessagingModule,
        NgxsReduxDevtoolsPluginModule.forRoot({
            disabled: environment.production,
        }),
        // NgxsResetPluginModule.forRoot(),
        // AgmCoreModule.forRoot({
        //     apiKey: 'AIzaSyB1XbSnNptOJ8BoD56LMPK6nMhEAVO91Xw',
        // }),
        SocketIoModule.forRoot(environment.socketIoConfig),
        MessagingModule,
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: ENVIRONMENT_TOKEN, useValue: environment },
        { provide: STORE_WRAPPER_TOKEN, useClass: StoreWrapperService },
        { provide: AUTH_SERVICE_TOKEN, useClass: AuthService },
        { provide: APP_SETTINGS_TOKEN, useClass: AppSettingsService },
        StatusBar,
        SplashScreen,
        // { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => { },
            deps: [Sentry.TraceService],
            multi: true,
        },
        NavigationService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }

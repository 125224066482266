import { JobsPlacementsComponent } from './jobs-placements.component';
import { JobStatus } from './enums/job-status.enum';
import { MAIN_PAGE } from './constants/routing.constants';
import { NgModule } from '@angular/core';
import { PlacementStatus } from './enums/placement-status.enum';
import { RouterModule, Routes } from '@angular/router';
import { RoutesDictionary } from './dictionaries/routes.dictionary';
import { SectionsEnum } from './enums/sections.enum';

const routes: Routes = [
    {
        path: '',
        component: JobsPlacementsComponent,
        children: [
            {
                path: '',
                redirectTo: MAIN_PAGE,
                pathMatch: 'full',
            },
            {
                path: RoutesDictionary.OPPORTUNITIES,
                // canActivate: [AuthGuard],
                loadChildren: () =>
                    import('./pages/jobs/jobs.module').then((m) => m.JobsPageModule),
                data: {
                    statuses: [JobStatus.OPEN_OPPORTUNITY],
                    titleKey: SectionsEnum.OPPORTUNITIES,
                },
                // resolve: { message: JobsLoaderResolver }
            },
            {
                path: RoutesDictionary.APPLICATIONS,
                // canActivate: [AuthGuard],
                loadChildren: () =>
                    import('./pages/jobs/jobs.module').then((m) => m.JobsPageModule),
                data: {
                    statuses: [JobStatus.CURRENT_APPLICATION, JobStatus.INTERESTED_SOME_SHIFTS],
                    titleKey: SectionsEnum.APPLICATIONS,
                },
            },
            {
                path: RoutesDictionary.ASSIGNMENTS,
                // canActivate: [AuthGuard],
                loadChildren: () =>
                    import('./pages/placements/placements.module').then(
                        (m) => m.PlacementsPageModule,
                    ),
                data: {
                    statuses: [PlacementStatus.PLACEMENT_CURRENT],
                    titleKey: SectionsEnum.ASSIGNMENTS,
                },
            },
            {
                path: RoutesDictionary.FINISHED_ASSIGNMENTS,
                // canActivate: [AuthGuard],
                loadChildren: () =>
                    import('./pages/placements/placements.module').then(
                        (m) => m.PlacementsPageModule,
                    ),
                data: {
                    statuses: [PlacementStatus.PLACEMENT_FINISHED],
                    titleKey: SectionsEnum.FINISHED_ASSIGNMENTS,
                },
            },
            {
                path: RoutesDictionary.UNANSWERED_OPPORTUNITIES,
                // canActivate: [AuthGuard],
                loadChildren: () =>
                    import('./pages/jobs/jobs.module').then((m) => m.JobsPageModule),
                data: {
                    statuses: [JobStatus.IGNORED_OPPORTUNITY],
                    titleKey: SectionsEnum.UNANSWERED_OPPORTUNITIES,
                },
            },
            {
                path: RoutesDictionary.DECLINED_OPPORTUNITIES,
                // canActivate: [AuthGuard],
                loadChildren: () =>
                    import('./pages/jobs/jobs.module').then((m) => m.JobsPageModule),
                data: {
                    statuses: [JobStatus.DECLINED_OPPORTUNITY],
                    titleKey: SectionsEnum.DECLINED_OPPORTUNITIES,
                },
            },
            {
                path: RoutesDictionary.SUCCESSFUL_APPLICATIONS,
                // canActivate: [AuthGuard],
                loadChildren: () =>
                    import('./pages/jobs/jobs.module').then((m) => m.JobsPageModule),
                data: {
                    statuses: [JobStatus.SUCCESSFUL_APPLICATION],
                    titleKey: SectionsEnum.SUCCESSFUL_APPLICATIONS,
                },
            },
            {
                path: RoutesDictionary.UNSUCCESSFUL_APPLICATIONS,
                // canActivate: [AuthGuard],
                loadChildren: () =>
                    import('./pages/jobs/jobs.module').then((m) => m.JobsPageModule),
                data: {
                    statuses: [JobStatus.UNSUCCESSFUL_APPLICATION],
                    titleKey: SectionsEnum.UNSUCCESSFUL_APPLICATIONS,
                },
            },
            {
                path: RoutesDictionary.UNANSWERED_APPLICATIONS,
                // canActivate: [AuthGuard],
                loadChildren: () =>
                    import('./pages/jobs/jobs.module').then((m) => m.JobsPageModule),
                data: {
                    statuses: [JobStatus.UNANSWERED_APPLICATION],
                    titleKey: SectionsEnum.UNANSWERED_APPLICATIONS,
                },
            },
            {
                path: RoutesDictionary.JOB,
                // canActivate: [AuthGuard],
                loadChildren: () =>
                    import('./pages/job/job.module').then((m) => m.JobPageModule),
            },
            {
                path: RoutesDictionary.SHIFT,
                // canActivate: [AuthGuard],
                loadChildren: () =>
                    import('./pages/shift/shift.module').then((m) => m.ShiftPageModule),
            },
            {
                path: RoutesDictionary.PLACEMENT,
                loadChildren: () =>
                    import('./pages/placement/placement.module').then(
                        (m) => m.PlacementPageModule,
                    ),
            },
            {
                path: RoutesDictionary.GEOFENCE,
                loadChildren: () =>
                    import('./pages/geofence/geofence.module').then(
                        (m) => m.GeofencePageModule,
                    ),
            },
            {
                path: RoutesDictionary.TIMESHEET_RECORD_TIME,
                loadChildren: () =>
                    import('./pages/record-time/record-time.module').then(
                        (m) => m.RecordTimePageModule,
                    ),
            },
            {
                path: RoutesDictionary.TIMESHEET_VIEW_TIMESHEET,
                loadChildren: () =>
                    import('./pages/view-timesheet/view-timesheet.module').then(
                        (m) => m.ViewTimesheetPageModule,
                    ),
            },
            {
                path: RoutesDictionary.TIMESHEET_RECORD_TIME_SELECTED,
                loadChildren: () =>
                    import(
                        './pages/record-time-selected/record-time-selected.module'
                    ).then((m) => m.RecordTimeSelectedPageModule),
            },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class JobsPlacementsRoutingModule {}

import { ConsultantSectionsEnum } from '../enums/consultant-sections.enum';

export const ConsultantMenuTitlesDictionary: Record<ConsultantSectionsEnum, string> = {
    [ConsultantSectionsEnum.DATA_COLLECTION]: 'Event & Process Records',
    [ConsultantSectionsEnum.HELP]: 'Help',
    [ConsultantSectionsEnum.HOME]: 'Home',
    [ConsultantSectionsEnum.JOBS_PLACEMENTS]: 'Jobs & Placements',
    [ConsultantSectionsEnum.JOBS]: 'Jobs',
    [ConsultantSectionsEnum.LOGOUT]: 'Logout',
    [ConsultantSectionsEnum.MAIN_INDEX_SEARCH]: 'Main index search',
    [ConsultantSectionsEnum.MESSAGING]: 'Messaging',
    [ConsultantSectionsEnum.SELECT_APPLICATION]: 'Select application',
    [ConsultantSectionsEnum.SETTINGS]: 'Account & Settings',
};

import { SectionsEnum } from '../enums/sections.enum';

// TODO: remove as unused after use bu token from app
export const EmptyTextDictionary = {
    [SectionsEnum.APPLICATIONS]: 'Jobs you have applied for will be shown on this screen.',
    [SectionsEnum.ASSIGNMENTS]: 'Your current confirmed work assignments will be shown on this screen. Click into any assignment to see schedules, locations, pay rates and other details. Click the ≡ icon to view the app menu.',
    [SectionsEnum.DECLINED_OPPORTUNITIES]: 'The jobs you declined to apply for will be shown on this screen.',
    [SectionsEnum.FINISHED_ASSIGNMENTS]: 'Your previous work assignments will be shown on this screen.',
    [SectionsEnum.OPPORTUNITIES]: 'Jobs available to apply for will be shown on this screen. Click any item to see schedules, pay rates, locations, other details and to apply for the job.',
    [SectionsEnum.SUCCESSFUL_APPLICATIONS]: 'The jobs you applied for and received an assignment for will be shown on this screen.',
    [SectionsEnum.UNANSWERED_APPLICATIONS]: 'The jobs you applied for but did not receive an assignment for will be shown on this screen.',
    [SectionsEnum.UNANSWERED_OPPORTUNITIES]: 'The jobs shared with you but no longer available will be shown on this screen.',
    [SectionsEnum.UNSUCCESSFUL_APPLICATIONS]: 'The jobs you applied for but did not receive an assignment for will be shown on this screen.',
};

import { catchError, map, retryWhen, tap } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { EMPTY, Observable, throwError } from 'rxjs';
import { Environment, ENVIRONMENT_TOKEN } from '@actassa/api';
import { AlertController } from '@ionic/angular';
import { genericRetryStrategy } from '../helpers/retry.strategy';
import { Plugins } from '@capacitor/core';

const { App } = Plugins;
const ALERT_TITLE = 'New App Version';
const platformDictionary = {
    android: 'Google Play Store',
    ios: 'Apple App Store',
}

interface VersionCheckResponseDTO {
    data: string;
    message: string;
    status: string;
}

@Injectable({
    providedIn: 'root',
})
export class VersionService {
    constructor(
        @Inject(ENVIRONMENT_TOKEN) private readonly environment: Environment,
        private readonly alertController: AlertController,
        private readonly http: HttpClient,
    ) { }

    public checkAppVersion$(appId: string, version: string, platform: string): Observable<string> {
        const url = this.buildUrl();

        return this.http.post<VersionCheckResponseDTO>(url, { app: appId, version, platform })
            .pipe(
                retryWhen(genericRetryStrategy()),
                map(({ data }: VersionCheckResponseDTO) => data),
                tap((status: string) => {
                    if (platform === 'web') {
                        return undefined;
                    }

                    if (!status || status === 'actual') {
                        return undefined;
                    }

                    const storeTitle = platformDictionary[platform];

                    if (status === 'mustUpdate') {
                        return this.mustUpdate(storeTitle);
                    }

                    if (status === 'canUpdate') {
                        return this.canUpdate(storeTitle);
                    }

                    return undefined;
                }),
                catchError((error: HttpErrorResponse) => throwError(error)),
            );
    }

    private buildUrl(): string {
        return `${this.environment.apiURL}/version/check`;
    }

    private async canUpdate(storeTitle: string): Promise<void> {
        const alert = await this.alertController.create({
            header: ALERT_TITLE,
            message: `A new version of ${this.environment.appName} is now available, with improvements for your best experience. Please close the app and get the update from ${storeTitle}. If this does not work, you may need to uninstall the current version and then install the new version.`,
            backdropDismiss: false,
            buttons: [
                {
                    text: 'Close App',
                    handler: () => App.exitApp(),
                },
                {
                    text: 'Remind Later',
                    role: 'cancel',
                },
            ]
        });

        await alert.present();
    }

    private async mustUpdate(storeTitle: string): Promise<void> {
        const alert = await this.alertController.create({
            header: ALERT_TITLE,
            message: `A new version of ${this.environment.appName} is now available, with essential improvements for your best experience. Please close the app and get the update from ${storeTitle}. If this does not work, you may need to uninstall the current version and then install the new version.`,
            backdropDismiss: false,
            buttons: [
                {
                    text: 'Close App',
                    handler: () => App.exitApp(),
                },
            ]
        });

        await alert.present();
    }
}

import { Injectable } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';
import { UserActionTypeEnum } from '../enums/user-action-type.enum';
import { ShowAppAlert, ShowAppMessage, UserAction, UserActionData } from '../interfaces/user-action.interface';

@Injectable({
    providedIn: 'root'
})
export class UserActionService {
    private actionMap: Record<UserActionTypeEnum, (params: UserActionData) => void> = {
        [UserActionTypeEnum.SHOW_APP_ALERT]: this.showAppAlert.bind(this),
        [UserActionTypeEnum.SHOW_APP_MESSAGE]: this.showAppMessage.bind(this),
    }

    constructor(
        private readonly alertController: AlertController,
        private readonly toastController: ToastController,
    ) { }

    public handle({ actionType, actionData }: UserAction): void {
        const handler = this.actionMap[actionType];

        if (!handler) {
            return;
        }

        handler(actionData);
    }

    private async showAppMessage({ header, message }: ShowAppAlert): Promise<void> {
        const toast = await this.toastController.create({
            header,
            message,
            duration: 10000,
            buttons: [
                {
                    text: 'OK',
                    role: 'cancel',
                }
            ]
        });

        toast.present();
    }

    private async showAppAlert({ header, message }: ShowAppMessage): Promise<void> {
        const alert = await this.alertController.create({
            header,
            message,
            backdropDismiss: false,
            buttons: [
                {
                    text: 'OK',
                    role: 'cancel',
                    cssClass: 'secondary',
                },
            ],
        });

        await alert.present();
    }
}

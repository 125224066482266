import { DOCUMENT } from '@angular/common';
import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable({
    providedIn: 'root',
})
export class GlobalErrorHandlerService implements ErrorHandler {
    private readonly window: Window & typeof globalThis;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private readonly alertController: AlertController,
    ) {
        this.window = this.document.defaultView;
    }

    public handleError(error: any): void {
        const chunkFailedMessage = /Loading chunk [a-zA-Z0-9-_]+ failed/;

        if (chunkFailedMessage.test(error.message)) {
            this.showErrorAlert();
        }
    }

    private async showErrorAlert(): Promise<void> {

        const alert = await this.alertController.create({
            header: 'Error',
            message: 'Error loading application modules. Some of the functionality is not available. Check your internet connection and refresh the page',
            backdropDismiss: false,
            buttons: [
                {
                    text: 'Reload',
                    handler: () => this.window.location.reload(),
                },
                {
                    text: 'Cancel',
                    role: 'cancel',
                },
            ]
        });

        await alert.present();
    }
}

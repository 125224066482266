import { AlertController, NavController } from '@ionic/angular';
import { catchError, switchMap, tap, take, throttleTime, finalize } from 'rxjs/operators';
import { ClearAwaitingShiftDataReport } from '../+state/app-state/actions/clear-awaiting-shiftdata-report';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, throwError, from, EMPTY, merge } from 'rxjs';
import { ReportEventType } from '../enums/report-event-type.enum';
import { SetAwaitingShiftDataReport } from '../+state/app-state/actions/set-awaiting-shiftdata-report';
import * as moment from 'moment';
import { Actions, ofActionDispatched, ofActionSuccessful } from '@ngxs/store';
import { LoadReportsEvent } from '../+state/app-state/actions/load-reports-event';
import { REPORTS_LOADING_THROTTLING_TIMEOUT } from '../constants/timer.constants';
import { LoadReportsSuccess } from '../+state/app-state/actions/load-reports-success';
import { LoadReportsFailure } from '../+state/app-state/actions/load-reports-failure';
import { UpdateReport } from '../+state/app-state/actions/update-report';
import { ReportInterface } from '../interfaces/report.interface';
import { isArray } from 'lodash-es';
import { SendReport } from '../+state/app-state/actions/send-report';
import { Environment, ENVIRONMENT_TOKEN, STORE_WRAPPER_TOKEN } from '@actassa/api';


@Injectable()
export class ReportsService {
    constructor(
        @Inject(STORE_WRAPPER_TOKEN) private storeWrapper,
        @Inject(ENVIRONMENT_TOKEN) private readonly environment: Environment,
        private readonly actions$: Actions,
        private readonly alertController: AlertController,
        private readonly http: HttpClient,
        private readonly navigationController: NavController,
    ) {
        this.init().subscribe();
    }

    public init(): Observable<void> {
        console.log('report subscribe')
        return merge(
            this.actions$
                .pipe(
                    ofActionDispatched(LoadReportsEvent),
                    throttleTime(REPORTS_LOADING_THROTTLING_TIMEOUT),
                    switchMap(() => this.loadReports$()),
                ),
            this.actions$
                .pipe(
                    ofActionDispatched(UpdateReport),
                    switchMap(({ report }: UpdateReport) => this.updateReport(report)),
                ),
            this.actions$
                .pipe(
                    ofActionSuccessful(SendReport),
                    switchMap(({ report }: SendReport) => this.sendReport(report)),
                )
        );
    }

    public sendReport(report: ReportInterface): Observable<void> {
        const header = this.buildSuccessHeader(report);
        const message = this.buildSuccessMessage(report);

        return from(this.alertController.create({
                header,
                message,
                buttons: [
                    {
                        text: 'Close',
                        role: 'cancel',
                        cssClass: 'secondary',
                    },
                ]
            }))
            .pipe(
                switchMap(alert => from(alert.present())),
                tap(() => this.storeWrapper.loadingStart()),
                switchMap(() => this.storeWrapper.isNetworkConnected$),
                take(1),
                switchMap((isConnected: boolean) => {
                    return isConnected ? this.setStatusOnline$(report) : this.setStatusOffline$(report);
                }),
                catchError(error => {
                   return throwError(error);
                }),
                finalize(() => this.storeWrapper.loadingEnd()),
            );
    }

    public sendReportToServer$(data: ReportInterface): Observable<any> {
        return this.http.post(`${this.environment.apiURL}/timesheet/report`, data)
            .pipe(
                take(1),
                tap(() => this.clearAwaitingShiftDataReport(data)),
            );
    }

    private updateReport(report: ReportInterface): Observable<void> {
        return this.storeWrapper.isNetworkConnected$
            .pipe(
                take(1),
                switchMap((isConnected: boolean) => {
                    return isConnected ? this.updateStatusOnline$(report) : this.setStatusOffline$(report);
                }),
                catchError(error => {
                    return throwError(error);
                }),
            );
    }

    private updateStatusOnline$(data: ReportInterface): Observable<any> {
        return this.http.post(`${this.environment.apiURL}/timesheet/report`, data)
            .pipe(
                take(1),
                tap((response: any) => {
                    if (response.status === 'ok') {
                        return;
                    }

                    this.storeWrapper.showToast(response.message);
                }),
                catchError((error: HttpErrorResponse) => {
                    this.storeWrapper.showToast(JSON.stringify(error));

                    return throwError(error);
                }),
            );
    }

    private setStatusOnline$(data: ReportInterface): Observable<any> {
        return this.http.post(`${this.environment.apiURL}/timesheet/report`, data)
            .pipe(
                take(1),
                tap((response: any) => {
                    if (response.status === 'ok') {
                        // this.setReport(data);
                        this.exitHandler();

                        return;
                    }

                    this.storeWrapper.showToast(response.message);
                }),
                catchError((error: HttpErrorResponse) => {
                    this.storeWrapper.showToast(JSON.stringify(error));

                    return throwError(error);
                }),
            );
    }

    private setStatusOffline$(data: ReportInterface): Observable<void> {
        return from(this.alertController.create({
            header: 'Offline mode',
            message: `There is no access to the Internet.
                When you are back online, just open this app to send the information to the office`,
            buttons: [
                {
                    text: 'Close',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                        this.setAwaitingShiftDataReport(data);
                        this.exitHandler();
                    }
                },
            ]
        })
        .then(alert => alert.present()));
    }

    private exitHandler(): void {
        this.navigationController.back();
    }

    private buildSuccessHeader(report: ReportInterface): string {
        if (report[ReportEventType.START] && report[ReportEventType.END]) {
            return 'End of Shift Recorded';
        }

        return 'Start of Shift Recorded';
    }

    private buildSuccessMessage(report: ReportInterface): string {
        if (report[ReportEventType.START] && report[ReportEventType.END]) {
            const startTime = moment(report[ReportEventType.START]);
            const endTime = moment(report[ReportEventType.END]);
            const shiftDuration = moment.duration(endTime.diff(startTime)).asHours();
            const breaksDuration = isArray(report.breaks)
                ? report.breaks.reduce((accum: number, userBreak: any) => {
                    const userBreakStartTime = moment(userBreak.start);
                    const userBreakEndTime = moment(userBreak.end);

                    return accum + moment.duration(userBreakEndTime.diff(userBreakStartTime)).asHours();
                }, 0)
                : 0;
            const duration = (shiftDuration - breaksDuration).toFixed(2);

            const eventName = `${report.event}`;
            const workedOn = `${moment(report.shiftStartDateTime).format('DD MMM YYYY')}`;
            const onDate = `${moment(report[report.event]).format('DD MMM YYYY')}`;
            const atTime = `${moment(report[report.event]).format('hh:mm a')}`;

            return `Time recorded for shift ${eventName} on ${onDate} at ${atTime}.
                A total of ${duration} hours have been added to the timesheet for ${workedOn}.`;
        }

        const event = `${report.event}`;
        const date = `${moment(report[report.event]).format('DD MMM YYYY')}`;
        const time = `${moment(report[report.event]).format('hh:mm a')}`;

        return `Time recorded for shift ${event} on ${date} at ${time}.`;
    }

    private loadReports$(): Observable<void> {
        return this.http.get(`${this.environment.apiURL}/reports`)
            .pipe(
                take(1),
                tap((response: any) => {
                    this.storeWrapper.showToast(response.message);

                    if (response.status === 'ok') {
                        this.loadReportsSuccess(response.data);

                        return;
                    }

                    this.loadReportsFailure();
                }),
                catchError((error) => {
                    // this.presentToast(error.message);
                    this.loadReportsFailure()

                    return EMPTY;
                }),
                finalize(() => EMPTY),
            );
    }

    // @Dispatch()
    // private setReport(data: ReportInterface): SetReport {
    //     return new SetReport(data);
    // }

    @Dispatch()
    private setAwaitingShiftDataReport(data: ReportInterface): SetAwaitingShiftDataReport {
        return new SetAwaitingShiftDataReport(data);
    }

    @Dispatch()
    private clearAwaitingShiftDataReport(data: ReportInterface): ClearAwaitingShiftDataReport {
        return new ClearAwaitingShiftDataReport(data);
    }

    @Dispatch()
    private loadReportsSuccess(data: ReportInterface[]): LoadReportsSuccess {
        return new LoadReportsSuccess(data);
    }

    @Dispatch()
    private loadReportsFailure(): LoadReportsFailure {
        return new LoadReportsFailure();
    }
}

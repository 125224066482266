import { addDays, endOfDay, getDay, getDayOfYear, getMonth, getYear, isAfter, isSameDay, nextDay, parse, previousDay, set } from 'date-fns';
import { first, isArray, last } from 'lodash-es';
import { PARSE_DATE_TIME_FORMAT } from '../constants/date-time-format.constants';
import { DailyShiftInterface } from '../interfaces/daily-shift.interface';
import { DateTimePairInterface } from '../interfaces/date-time-pair.interface';
import { DiaryInterface } from '../interfaces/diary.interface';
import { DailyShiftDTOInterface } from '../interfaces/dto/daily-shift.dto.interface';
import { DiaryDTOInterface } from '../interfaces/dto/dairy.dto.interface';
import { JobDataDTOInterface } from '../interfaces/dto/job-data.dto.interface';
import { JobDTOInterface } from '../interfaces/dto/job.dto.interface';
import { PlacementDTOInterface } from '../interfaces/dto/placement.dto.interface';
import { ShiftDTOInterface } from '../interfaces/dto/shift.dto.interface';
import { JobDataInterface } from '../interfaces/job-data.interface';
import { JobInterface } from '../interfaces/job.interface';
import { PlacementInterface } from '../interfaces/placement.interface';
import { ShiftInterface } from '../interfaces/shift.interface';

function parseDate(dateString: string, parseFormat: string = PARSE_DATE_TIME_FORMAT): Date {
    return parse(dateString, parseFormat, new Date());
}

function parseDiaryItem(dairyItemDto: DiaryDTOInterface): DiaryInterface {
    return {
        ...dairyItemDto,
        shiftStartDateTime: parseDate(dairyItemDto.shiftStartDateTime),
        shiftEndDateTime: parseDate(dairyItemDto.shiftEndDateTime),
    };
}

function parseDailyShift(dailyShiftDto: DailyShiftDTOInterface): DailyShiftInterface {
    return {
        ...dailyShiftDto,
        dayNumber: getDay(parseDate(dailyShiftDto.day, 'iiii')),
        shiftStartTime: set(parseDate(dailyShiftDto.shiftStartTime), {
            year: 1970,
            month: 0,
            date: 1,
        }),
        shiftEndTime: set(parseDate(dailyShiftDto.shiftEndTime), {
            year: 1970,
            month: 0,
            date: 1,
        }),
    };
}

function datesComparer(a: Date, b: Date, sortDirection: 'ASC' | 'DESC' = 'ASC'): number {
    const sortDirectionMulti = sortDirection === 'ASC' ? 1 : -1;

    return (a.getTime() - b.getTime()) * sortDirectionMulti;
}

function getShiftsAndDates(jobDataOrPlacement: JobDataInterface | PlacementInterface): {
    diary: Array<DiaryInterface>,
    dailyShifts: Array<DailyShiftInterface>,
    startDate: Date,
    endDate: Date
} {
    const { diary, dailyShifts } = jobDataOrPlacement;
    const { placementStartDate, placementEndDate } = jobDataOrPlacement as PlacementInterface;
    let { startDate, endDate } = jobDataOrPlacement as JobDataInterface;

    startDate = startDate || placementStartDate;
    endDate = endDate || placementEndDate;

    return {
        diary,
        dailyShifts,
        startDate,
        endDate,
    };

}

// HELPERS
function copyDate(toDate: Date, fromDate: Date): Date {
    return toDate = set(toDate, {
        date: getDayOfYear(fromDate),
        month: getMonth(fromDate),
        year: getYear(fromDate),
    });
}

function getToday(): Date {
    const now = Date.now();

    return endOfDay(now);
}

function getDateTimePairFromDailyShift(date: Date, dailyShift: DailyShiftInterface): DateTimePairInterface {
    const startDateTime = copyDate(dailyShift.shiftStartTime, date);
    const endDateTime = copyDate(dailyShift.shiftEndTime, date);

    if (isAfter(startDateTime, endDateTime)) {
        addDays(endDateTime, 1);
    }

    return {
        startDateTime,
        endDateTime,
    };
}

// FIND FIRST SHIFT AFTER DATE

function getFirstDateAfter(pointDateTime: Date, daysOfWeek: Array<Day>, strict: boolean): {
    date: Date
    dayNumber: Day
} {
    const pointDateDayOfWeek = getDay(pointDateTime);
    const sortedDaysOfWeek = daysOfWeek.sort();
    const daysOfWeekAfterPointDate = sortedDaysOfWeek.filter(dayNumber =>
        dayNumber > pointDateDayOfWeek ||
        (!strict && dayNumber === pointDateDayOfWeek),
    );
    const firstDayOfWeekAfterPoint = daysOfWeekAfterPointDate.length
        ? first(daysOfWeekAfterPointDate)
        : first(daysOfWeek);

    const date = nextDay(pointDateTime, firstDayOfWeekAfterPoint);

    return {
        date,
        dayNumber: firstDayOfWeekAfterPoint,
    };
}

function getFirstDateTimePairAfter(pointDateTime: Date, dailyShifts: Array<DailyShiftInterface>, strict: boolean): DateTimePairInterface {
    const dayNumbers = dailyShifts.map(({ dayNumber }) => dayNumber);

    const { date, dayNumber } = getFirstDateAfter(pointDateTime, dayNumbers, strict);

    const foundDailyShift = dailyShifts.find((dailyShift) => dailyShift.dayNumber === dayNumber);

    return getDateTimePairFromDailyShift(date, foundDailyShift);
}

function getJobOrPlacementFirstFutureShift(jobDataOrPlacement: JobDataInterface | PlacementInterface): DateTimePairInterface {
    const { diary, dailyShifts, startDate, endDate } = getShiftsAndDates(jobDataOrPlacement);

    if (diary) {
        const today = getToday();

        const firstFutureDairyItem = diary.find(({ shiftStartDateTime }) => isAfter(shiftStartDateTime, today)) || last(diary);

        return {
            startDateTime: firstFutureDairyItem.shiftStartDateTime,
            endDateTime: firstFutureDairyItem.shiftEndDateTime,
        };

    } else if (dailyShifts && startDate && endDate) {

        const today = getToday();

        if (!isAfter(today, endDate) && !isSameDay(today, endDate)) {
            const firstDailyShiftDateTimePair = getFirstDateTimePairAfter(today, dailyShifts, true);

            return {
                startDateTime: firstDailyShiftDateTimePair.startDateTime,
                endDateTime: firstDailyShiftDateTimePair.endDateTime,
            };
        } else {
            const lastDailyShiftDateTimePair = getLastDateTimePairBefore(endDate, dailyShifts, false);

            return lastDailyShiftDateTimePair;
        }

    } else if (startDate && endDate) {
        return {
            startDateTime: startDate,
            endDateTime: endDate,
        };
    } else if (startDate) {
        return {
            startDateTime: startDate,
            endDateTime: null,
        };
    } else {
        return undefined;
    }
}

function getLastDateBefore(pointDateTime: Date, dayNumbers: Array<Day>, strict: boolean): {
    date: Date
    dayNumber: Day
} {
    const pointDateDayOfWeek = getDay(pointDateTime);
    const sortedDaysOfWeek = dayNumbers.sort();
    const daysOfWeekDeforePointDate = sortedDaysOfWeek.filter(dayOfWeek =>
        dayOfWeek <= pointDateDayOfWeek ||
        (!strict && dayOfWeek === pointDateDayOfWeek),
    );
    const lastDayOfWeekBeforePoint = daysOfWeekDeforePointDate.length
        ? last(daysOfWeekDeforePointDate)
        : last(dayNumbers);

    const date = previousDay(pointDateTime, lastDayOfWeekBeforePoint);

    return {
        date,
        dayNumber: lastDayOfWeekBeforePoint,
    };
}

function getLastDateTimePairBefore(endDate: Date, dailyShifts: Array<DailyShiftInterface>, strict: boolean): DateTimePairInterface {
    const dayNumbers = dailyShifts.map(({ dayNumber }) => dayNumber);

    const { date, dayNumber } = getLastDateBefore(endDate, dayNumbers, strict);

    const foundDailyShift = dailyShifts.find((dailyShift) => dailyShift.dayNumber === dayNumber);

    return getDateTimePairFromDailyShift(date, foundDailyShift);
}

// MAP

function getJobOrPlacementPeriodDateTimePair(jobDataOrPlacement: JobDataInterface | PlacementInterface): DateTimePairInterface {
    const { diary, dailyShifts, startDate, endDate } = getShiftsAndDates(jobDataOrPlacement);

    if (diary) {
        return {
            startDateTime: first(diary).shiftStartDateTime,
            endDateTime: last(diary).shiftEndDateTime,
        };
    } else if (dailyShifts && startDate && endDate) {
        const firstDailyShiftDateTimePair = getFirstDateTimePairAfter(startDate, dailyShifts, false);
        const lastDailyShiftDateTimePair = getLastDateTimePairBefore(endDate, dailyShifts, false);

        return {
            startDateTime: firstDailyShiftDateTimePair.startDateTime,
            endDateTime: lastDailyShiftDateTimePair.endDateTime,
        };
    } else if (startDate && endDate) {
        return {
            startDateTime: startDate,
            endDateTime: endDate,
        };
    } else {
        return undefined;
    }
}

function diaryAndShiftsDetailsFromDto(dto: { diary?: Array<DiaryDTOInterface>, dailyShifts?: Array<DailyShiftDTOInterface> })
    : { diary?: Array<DiaryInterface>, dailyShifts?: Array<DailyShiftInterface> } {
    const diary = isArray(dto.diary) && dto.diary.length
        ? dto.diary
            .map(parseDiaryItem)
            .sort((a, b) => datesComparer(a.shiftStartDateTime, b.shiftEndDateTime))
        // case when dairy sent as daily shifts O_o
        : isArray(dto.dailyShifts) &&
            dto.dailyShifts.length &&
            (first(dto.dailyShifts) as any).shiftStartDateTime
            ? ((dto.dailyShifts as any) as Array<DiaryDTOInterface>)
                .map(parseDiaryItem)
                .sort((a, b) => datesComparer(a.shiftStartDateTime, b.shiftEndDateTime))
            : undefined;

    const dailyShifts = !diary && isArray(dto.dailyShifts) && dto.dailyShifts.length
        ? dto.dailyShifts
            .map(parseDailyShift)
            .sort((a, b) => datesComparer(a.shiftStartTime, b.shiftEndTime))
        : undefined;

    return {
        ...dto,
        diary,
        dailyShifts,
    };
}

function jobDataFromDto(
    jobDataDto: JobDataDTOInterface,
): JobDataInterface {
    const startDate = parseDate(jobDataDto.startDate);
    const endDate = parseDate(jobDataDto.endDate);

    const jobData: JobDataInterface = {
        ...jobDataDto,
        ...diaryAndShiftsDetailsFromDto(jobDataDto),
        startDate,
        endDate,
    } as JobDataInterface;

    jobData.nextOrLastShiftDateTimePair = getJobOrPlacementFirstFutureShift(jobData);
    jobData.firstAndLastShiftsDateTimePair = getJobOrPlacementPeriodDateTimePair(jobData);

    return jobData;
}

function placementsFromDto(
    placementDto: PlacementDTOInterface,
): PlacementInterface {
    const placementStartDate = parseDate(placementDto.placementStartDate);
    const placementEndDate = parseDate(placementDto.placementEndDate);

    const placement: PlacementInterface = {
        ...placementDto,
        ...diaryAndShiftsDetailsFromDto(placementDto),
        placementStartDate,
        placementEndDate,
    } as PlacementInterface;

    placement.nextOrLastShiftDateTimePair = getJobOrPlacementFirstFutureShift(placement);
    placement.firstAndLastShiftsDateTimePair = getJobOrPlacementPeriodDateTimePair(placement);

    return placement;
}

export function jobFromDto(jobDto: JobDTOInterface): JobInterface {
    return {
        ...jobDto,
        jobData: jobDataFromDto(jobDto.jobData) as JobDataInterface,
    };
}

export function shiftFromDto(shiftDto: ShiftDTOInterface): ShiftInterface {
    return {
        ...shiftDto,
        jobData: jobDataFromDto(shiftDto.jobData) as JobDataInterface,
    };
}

export function placementFromDto(placementDto: PlacementDTOInterface): PlacementInterface {
    return placementsFromDto(placementDto) as PlacementInterface;
}
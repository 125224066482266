import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { NgxsModule } from '@ngxs/store';
import { MessagingState } from './+state/messaging.state';
import { MessagingRoutingModule } from './messaging-routing.module';
import { MessagingComponent } from './messaging.component';

@NgModule({
    declarations: [
        MessagingComponent,

    ],
    imports: [
        CommonModule,
        IonicModule,
        MessagingRoutingModule,
        NgxsModule.forFeature([MessagingState]),
    ],
})
export class MessagingModule {
    constructor() {
        console.log('MessagingModule init');
    }
}

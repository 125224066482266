import { Environment, ENVIRONMENT_TOKEN, ServerResponseInterface, STORE_WRAPPER_TOKEN } from '@actassa/api';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Actions, ofActionDispatched, ofActionSuccessful } from '@ngxs/store';
import { merge, Observable, of } from 'rxjs';
import { tap, take, catchError, throttleTime, switchMap, finalize } from 'rxjs/operators';
import { ChangePlacementStatus } from '../+state/app-state/actions/change-placement-status';
import { ClearPlacementsLoadingStatus } from '../+state/app-state/actions/clear-placements-loading-status';
import { LoadPlacementsEvent } from '../+state/app-state/actions/load-placements-event';
import { SetPlacementUserViewStatus } from '../+state/app-state/actions/set-placement-user-view-status';
import { SetPlacementsLoadingStatus } from '../+state/app-state/actions/set-placements-loading-status';
import { UpdatePlacements } from '../+state/app-state/actions/update-placements';
import { PLACEMENTS_LOADING_THROTTLING_TIMEOUT } from '../constants/timer.constants';
import { PlacementStatus } from '../enums/placement-status.enum';
import { placementFromDto } from '../helpers/mapper.helper';
import { PlacementDTOInterface } from '../interfaces/dto/placement.dto.interface';
import { PlacementInterface } from '../interfaces/placement.interface';

@Injectable()
export class PlacementsService {
    constructor(
        @Inject(ENVIRONMENT_TOKEN) private readonly environment: Environment,
        @Inject(STORE_WRAPPER_TOKEN) private storeWrapper,
        private readonly actions$: Actions,
        private readonly http: HttpClient,
    ) {
        this.init().subscribe();
    }

    public init(): Observable<void | ServerResponseInterface<Array<PlacementDTOInterface>>> {
        return merge(
            this.actions$
                .pipe(
                    ofActionDispatched(LoadPlacementsEvent),
                    throttleTime(PLACEMENTS_LOADING_THROTTLING_TIMEOUT),
                    switchMap(() => this.load()),
                ),
            this.actions$
                .pipe(
                    ofActionSuccessful(ChangePlacementStatus),
                    switchMap(({ id, status }: ChangePlacementStatus) => this.changeStatus(id, status)),
                ),
            this.actions$
                .pipe(
                    ofActionSuccessful(SetPlacementUserViewStatus),
                    switchMap(({ placementId }: SetPlacementUserViewStatus) => this.setPlacementUserViewStatus(placementId)),
                ),
        );
    }

    public changeStatus(id: number, status: PlacementStatus): Observable<ServerResponseInterface<Array<PlacementDTOInterface>>> {
        return this.http.post<ServerResponseInterface<Array<PlacementDTOInterface>>>(
            `${this.environment.apiURL}/placement/change/${id}`,
            { status },
        ).pipe(
            take(1),
            tap((response: ServerResponseInterface<Array<PlacementDTOInterface>>) => {
                if (response.status === 'ok') {
                    this.updatePlacements(response.data.map(placementFromDto));

                    return;
                }

                this.storeWrapper.showToast(response.message);
            }),
            catchError((error) => {
                this.storeWrapper.showToast(error.message);

                return of({ data: [] } as ServerResponseInterface<Array<PlacementDTOInterface>>);
            }),
        );

    }

    public load(): Observable<ServerResponseInterface<Array<PlacementDTOInterface>>> {
        this.setPlacementsLoading();

        return this.http.get(`${this.environment.apiURL}/placements`)
            .pipe(
                tap((response: ServerResponseInterface<Array<PlacementDTOInterface>>) => {
                    this.storeWrapper.showToast(response.message);

                    if (response.status === 'ok') {
                        this.updatePlacements(response.data.map(placementFromDto));

                        return;
                    }
                }),
                take(1),
                catchError((error) => {
                    this.storeWrapper.showToast(error.message);

                    return of({ data: [] } as ServerResponseInterface<Array<PlacementDTOInterface>>);
                }),
                finalize(() => this.clearPlacementsLoading()),
            );
    }

    private setPlacementUserViewStatus(placementId: number): Observable<void> {
        return this.http.patch(`${this.environment.apiURL}/placement/view-status`, {
            placementId,
        })
            .pipe(
                take(1),
                tap((response: any) => {
                    if (response.status === 'ok') {
                        return;
                    }

                    this.storeWrapper.showToast(response.message);
                }),
                catchError((error) => {
                    this.storeWrapper.showToast(error.message);

                    return of([]);
                }),
            );
    }

    @Dispatch()
    private updatePlacements(data: Array<PlacementInterface>): UpdatePlacements {
        return new UpdatePlacements(data);
    }

    @Dispatch()
    private setPlacementsLoading(): SetPlacementsLoadingStatus {
        return new SetPlacementsLoadingStatus();
    }

    @Dispatch()
    private clearPlacementsLoading(): ClearPlacementsLoadingStatus {
        return new ClearPlacementsLoadingStatus();
    }
}

import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'dialogDate',
})
export class DialogDatePipe implements PipeTransform {
    public transform(dateTime: moment.Moment | Date | string): string | undefined {
        const currentDate = moment(dateTime);

        if (currentDate.isSame(moment(), 'day')) {
            return currentDate.format('h:mm A');
        }

        if (currentDate.isSame(moment().add(-1, 'day'), 'day')) {
            return 'Yesterday';
        }

        const isSameYear = currentDate.isSame(moment(), 'year');

        return isSameYear ? currentDate.format('D MMM') : currentDate.format('DD.MM.YYYY');
    }

}

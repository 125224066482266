import { StoreWrapperInterface, STORE_WRAPPER_TOKEN } from '@actassa/api';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MenuTitlesDictionary } from '../dictionaries/menu-titles.dictionary';
import { SectionsEnum } from '../enums/sections.enum';
import { JOBS_PLACEMENTS_TITLES_TOKEN } from '../tokens/titles.token';

@Injectable()
export class TitlesService {
    constructor(
        @Optional() @Inject(JOBS_PLACEMENTS_TITLES_TOKEN) private titlesConfig: Record<SectionsEnum, string>,
        @Inject(STORE_WRAPPER_TOKEN) private storeWrapper: StoreWrapperInterface,
    ) { }

    public get(key: string): string {
        return this.titlesConfig?.[key] || MenuTitlesDictionary[key] || '';
    }

    public get$(key: string): Observable<string> {
        return this.storeWrapper.getPageTitle$(key)
            .pipe(
                map((title: string | undefined) => title || this.get(key)),
            );
    }
}

import { StoreWrapperInterface, STORE_WRAPPER_TOKEN } from '@actassa/api';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EmptyTextDictionary } from '../dictionaries/empty-text.dictionary';
import { SectionsEnum } from '../enums/sections.enum';
import { JOBS_PLACEMENTS_EMPTY_TEXT_TOKEN } from '../tokens/empty-text.token';

@Injectable()
export class EmptyTextService {

    constructor(
        @Optional() @Inject(JOBS_PLACEMENTS_EMPTY_TEXT_TOKEN) private emptyTextsConfig: Partial<Record<SectionsEnum, string>>,
        @Inject(STORE_WRAPPER_TOKEN) private storeWrapper: StoreWrapperInterface,
    ) { }

    public get(key: string): string {
        return this.emptyTextsConfig?.[key] || EmptyTextDictionary[key] || '';
    }

    public get$(key: string): Observable<string> {
        return this.storeWrapper.getMenuItemProperty$(key, 'emptyText')
            .pipe(
                map((emptyText: string | undefined) => emptyText || this.get(key)),
            );
    }
}

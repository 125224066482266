
import { MainMenuItemInterface } from '@actassa/api';
import { Component, Input } from '@angular/core';
import { InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';

@Component({
    selector: 'actassa-main-menu-item',
    templateUrl: './main-menu-item.component.html',
    styleUrls: ['./main-menu-item.component.scss'],
})
export class MainMenuItemComponent {

    @Input() public isDebugMode: boolean;
    @Input() public menuItem: MainMenuItemInterface;

    constructor(
        private readonly iab: InAppBrowser,
    ) { }

    public get hasSubmenu(): boolean {
        return this.menuItem.submenu?.length > 0;
    }

    public get detailIcon(): string {
        return this.menuItem.isOpen ? 'chevron-down' : 'chevron-forward';
    }

    public get isShowMenuItem(): boolean {
        const { isActive, isDebugOnly } = this.menuItem;

        if (isActive) {
            if (this.isDebugMode) {
                return true;
            }

            return !isDebugOnly;
        }

        return false;
    }

    public get labelClass(): Record<string, boolean> {
        return {
            'no-icon': !this.menuItem.icon,
        };
    }

    public get isCustomIcon(): boolean {
        return /assets/.test(this.menuItem.icon);
    }

    public get isExternalLink(): boolean {
        return /^https:\/\//.test(this.menuItem.url);
    }

    public toggleMenuItem(menuItem: MainMenuItemInterface): void {
        menuItem.isOpen = !menuItem.isOpen;
    }

    public openExternalLink(link: string): void {
        const options: InAppBrowserOptions = {
            zoom: 'no',
        };

        this.iab.create(link, '_blank', options);
    }

    public trackByMenuItem(index: number, menuItem: MainMenuItemInterface): string {
        return menuItem.key;
    }
}

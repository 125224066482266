import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResetState } from './actions/reset-state';
import { State, Action, StateContext, Select, Selector } from '@ngxs/store';
import { UserInterface } from '@actassa/api';
import { RootState } from 'state/root-state/root.state';
import { SetRooms } from './actions/set-rooms';
import { JoinRoom } from './actions/join-room';
import { SetMessages } from './actions/set-messages';
import { AddMessage } from './actions/add-message';
import { ClearMessages } from './actions/clear-messages';
import { SetSearchUsers } from './actions/set-search-users';
import { MessagingStateInterface } from '../interfaces/messaging-state.interface';
import { Room } from '../models/room';
import { Message } from '../models/message';
import { SearchUserInterface } from '../interfaces/search-user.interface';
import { SetPlaceholder } from './actions/set-placeholder';
import { map, mapTo, take, tap } from 'rxjs/operators';
import { SetRoom } from './actions/set-room';
import { UpdateMessage } from './actions/update-message';
import { LeaveChatRoom } from './actions/leave-chat-room';
import { UserOnline } from './actions/user-online';
import { UserOffline } from './actions/user-offline';
import { ShowToast } from 'state/root-state/actions/show-toast';
import { SetAlertFlag } from './actions/set-alert-flag';
import { ClearAlertFlag } from './actions/clear-alert-flag';
import { isArray } from 'lodash-es';
import { isMessageInRoom, isMessageRead } from '../helpers/message.helper';

const DEFAULT_STATE_DATA: MessagingStateInterface = {
    data: null,
    messages: [],
    room: null,
    rooms: [],
    searchUsers: [],
    placeholder: '',
    isContinueAlert: false,
};

@State<MessagingStateInterface>({
    name: 'messaging',
    defaults: DEFAULT_STATE_DATA,
})
@Injectable()
export class MessagingState {
    @Select(RootState.user$) public user$: Observable<UserInterface>;

    @Selector()
    public static room$(state: MessagingStateInterface): Room | null {
        return state.room;
    }

    @Selector()
    public static rooms$(state: MessagingStateInterface): Room[] {
        return state.rooms;
    }

    @Selector()
    public static messages$(state: MessagingStateInterface): Message[] {
        return state.messages;
    }

    @Selector()
    public static searchUsers$(state: MessagingStateInterface): SearchUserInterface[] | string {
        return state.searchUsers;
    }

    @Selector()
    public static placeholder$(state: MessagingStateInterface): string {
        return state.placeholder;
    }

    @Selector()
    public static isShowContinueAlert$(state: MessagingStateInterface): boolean {
        return state.isContinueAlert;
    }

    @Action(SetAlertFlag)
    public setAlertFlag(stateContext: StateContext<MessagingStateInterface>): void {
        stateContext.patchState({ isContinueAlert: true });
    }

    @Action(ClearAlertFlag)
    public clearAlertFlag(stateContext: StateContext<MessagingStateInterface>): void {
        stateContext.patchState({ isContinueAlert: false });
    }

    @Action(JoinRoom)
    public joinRoom(stateContext: StateContext<MessagingStateInterface>, { room }: JoinRoom): void {
        stateContext.patchState({ room: { ...room } });
    }

    @Action(LeaveChatRoom)
    public leaveChatRoom(stateContext: StateContext<MessagingStateInterface>, { room, uuid }: LeaveChatRoom): void {
        stateContext.patchState({ room: null });
    }

    @Action(SetRooms)
    public setRooms(stateContext: StateContext<MessagingStateInterface>, { rooms }: SetRooms): Observable<void> {
        return this.user$.pipe(
            take(1),
            map((user: UserInterface) => rooms
                .filter((room: Room) => isArray(room?.users))
                .map((room: Room) => {
                    const roomUserId = room.users.filter((uuid: string) => uuid !== user.uuid)[0];

                    return {
                        ...room,
                        name: room.names?.[roomUserId],
                    };
                })
            ),
            tap((list: Room[]) => stateContext.patchState({ rooms: [...list] })),
            mapTo(void (0)),
        );
    }

    @Action(SetRoom)
    public setRoom(stateContext: StateContext<MessagingStateInterface>, { room }: SetRoom): Observable<void> {
        return this.user$.pipe(
            take(1),
            map((user: UserInterface) => {
                const roomUserId = room.users?.filter((uuid: string) => uuid !== user.uuid)[0];

                return {
                    ...room,
                    name: room.names?.[roomUserId],
                };
            }),
            tap((r: Room) => {
                const { rooms } = stateContext.getState();
                const existedRoom = rooms.find((_room: Room) => _room.id === r.id);

                if (!existedRoom) {
                    stateContext.patchState({ rooms: [r, ...rooms] });
                }
            }),
            mapTo(void (0)),
        );
    }

    @Action(AddMessage)
    public addMessage(stateContext: StateContext<MessagingStateInterface>, { message }: AddMessage) {
        const { messages, room } = stateContext.getState();

        stateContext.patchState({ messages: [...messages, message] });

        this.user$.pipe(
            take(1),
            map((user: UserInterface) => {
                if (!isMessageRead(message, user) && !isMessageInRoom(message, room)) {
                    stateContext.dispatch(new ShowToast(`New message\nFrom: ${message.from}\n${message.text}`));
                }
            }),
        );
    }

    @Action(UpdateMessage)
    public updateMessage(stateContext: StateContext<MessagingStateInterface>, { message }: UpdateMessage): void {
        const { messages } = stateContext.getState();
        const messageIndex = messages.findIndex((m: Message) => m.id === message.id);

        if (messageIndex < 0) {
            // Случай, когда сообщения локально нет?
            stateContext.patchState({ messages: [...messages, message] });

            return;
        }

        stateContext.patchState({
            messages: [
                ...messages.slice(0, messageIndex),
                message,
                ...messages.slice(messageIndex + 1),
            ]
        });
    }

    @Action(ClearMessages)
    public clearMessages(stateContext: StateContext<MessagingStateInterface>) {
        stateContext.patchState({ messages: [] });
    }

    @Action(SetMessages)
    public setMessages(stateContext: StateContext<MessagingStateInterface>, { messages }: SetMessages) {
        stateContext.patchState({ messages: [...messages] });
    }

    @Action(ResetState)
    public resetState(stateContext: StateContext<MessagingStateInterface>): void {
        stateContext.setState(DEFAULT_STATE_DATA);
    }

    @Action(SetSearchUsers)
    public setSearchUsers(stateContext: StateContext<MessagingStateInterface>, { users }: SetSearchUsers): void {
        stateContext.patchState({ searchUsers: [...users] });
    }

    @Action(SetPlaceholder)
    public setPlaceholder(stateContext: StateContext<MessagingStateInterface>, { message }: SetPlaceholder): void {
        stateContext.patchState({ placeholder: message });
    }

    @Action(UserOnline)
    public userOnline(stateContext: StateContext<MessagingStateInterface>, { uuid }: UserOnline): void {
        const { rooms, room } = stateContext.getState();

        stateContext.patchState({
            rooms: rooms.map((r: Room) => {
                if (r.names[uuid]) {
                    return {
                        ...r,
                        isUserOnline: true,
                    };
                }

                return r;
            }),
            room: room?.names[uuid] ? { ...room, isUserOnline: true } : room,
        });
    }

    @Action(UserOffline)
    public userOffline(stateContext: StateContext<MessagingStateInterface>, { uuid }: UserOffline): void {
        const { rooms, room } = stateContext.getState();

        stateContext.patchState({
            rooms: rooms.map((r: Room) => {
                if (r.names[uuid]) {
                    return {
                        ...r,
                        isUserOnline: false,
                    };
                }

                return r;
            }),
            room: room?.names[uuid] ? { ...room, isUserOnline: false } : room,
        });
    }
}

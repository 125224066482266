import { ApplicationsEnum } from '../enums/applications.enum';

export const AppTitlesDictionary: Record<ApplicationsEnum, string> = {
    [ApplicationsEnum.CANDIDATE]: 'CANDIDATE',
    [ApplicationsEnum.CANDIDATE_ALL]: 'CANDIDATE - ALL',
    [ApplicationsEnum.CANDIDATE_ENTERPRISE]: 'CANDIDATE - ENTERPRISE',
    [ApplicationsEnum.CANDIDATE_NSWBC]: 'CANDIDATE - NSWBC',
    [ApplicationsEnum.CANDIDATE_ONESTAFF]: 'CANDIDATE - ONESTAFF',
    [ApplicationsEnum.CANDIDATE_SYMMETRY]: 'CANDIDATE - SYMMETRY',
    [ApplicationsEnum.CANDIDATE_TRN]: 'CANDIDATE - TRN',
    [ApplicationsEnum.CANDIDATE_ELE]: 'CANDIDATE - ELE',
    [ApplicationsEnum.CLIENT]: 'CLIENT',
    [ApplicationsEnum.CLIENT_TRN]: 'CLIENT - TRN',
    [ApplicationsEnum.CLIENT_ELE]: 'CLIENT - ELE',
    [ApplicationsEnum.CONSULTANT]: 'CONSULTANT',
    [ApplicationsEnum.CONSULTANT_ALL]: 'CONSULTANT - ALL',
    [ApplicationsEnum.CONSULTANT_ENTERPRISE]: 'CONSULTANT - ENTERPRISE',
    [ApplicationsEnum.CONSULTANT_NSWBC]: 'CONSULTANT - NSWBC',
    [ApplicationsEnum.CONSULTANT_ONESTAFF]: 'CONSULTANT - ONESTAFF',
    [ApplicationsEnum.CONSULTANT_SYMMETRY]: 'CONSULTANT - SYMMETRY',
    [ApplicationsEnum.CONSULTANT_TRN]: 'CONSULTANT - TRN',
    [ApplicationsEnum.CONSULTANT_ELE]: 'CONSULTANT - ELE',
};

import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { RootState } from 'state/root-state/root.state';
import { Select, Store } from '@ngxs/store';
import { UpdateRedirectURL } from 'state/root-state/actions/update-redirect-url';
import { Navigate } from '@ngxs/router-plugin';
import { RoutesDictionary } from 'dictionaries/routes.dictionary';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    @Select(RootState.isAuthenticated$) public isAuthenticated$: Observable<boolean>;

    constructor(private store: Store) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
            const { url } = state;
            const isAuthenticated = this.store.selectSnapshot(RootState.isAuthenticated$);

            if (isAuthenticated) {
                return true;
            }

            // this.store.dispatch([
            //     new UpdateRedirectURL(url),
            //     new Navigate([RoutesDictionary.LOGIN]),
            // ]);

            this.gotoLogin(url);

            return false;
    }

    @Dispatch()
    private gotoLogin(url: string): (UpdateRedirectURL | Navigate)[] {
        return [
            new UpdateRedirectURL(url),
            new Navigate([RoutesDictionary.LOGIN]),
        ];
    }
}

import { JobDTOInterface } from '../interfaces/dto/job.dto.interface';
import { PlacementDTOInterface } from '../interfaces/dto/placement.dto.interface';
import { ShiftDTOInterface } from '../interfaces/dto/shift.dto.interface';
import { JobsPlacementsInterface } from '../interfaces/jobs-placements-state.interface';
import { jobFromDto, placementFromDto, shiftFromDto } from './mapper.helper';

interface SerializedState {
    [key: string]: any,
    jobsplacements: JobsPlacementsSerializedState,
}

interface DeserializedState {
    [key: string]: any,
    jobsplacements: JobsPlacementsInterface,
}

declare type JobsPlacementsSerializedState =
    Omit<JobsPlacementsInterface, 'job' | 'shift' | 'placement' | 'jobs' | 'shifts' | 'placements'> &
    {
        job: JobDTOInterface,
        jobs: Array<JobDTOInterface>,
        shift: ShiftDTOInterface,
        shifts: Array<ShiftDTOInterface>,
        placement: PlacementDTOInterface,
        placements: Array<PlacementDTOInterface>,
    };

export function jobsPlacementsAfterDeserialize(serializedState: SerializedState, key: string): DeserializedState {
    const jobsPlacementStore = serializedState.jobsplacements;

    return {
        ...serializedState,
        jobsplacements: jobsPlacementStore ? {
            ...jobsPlacementStore,
            job: jobsPlacementStore.job ? jobFromDto(jobsPlacementStore.job) : null,
            jobs: jobsPlacementStore.jobs.map(jobFromDto),
            shift: jobsPlacementStore.shift ? shiftFromDto(jobsPlacementStore.shift) : null,
            shifts: jobsPlacementStore.jobs.map(shiftFromDto),
            placement: jobsPlacementStore.placement ? placementFromDto(jobsPlacementStore.placement) : null,
            placements: jobsPlacementStore.placements.map(placementFromDto),
        } : undefined,
    };
}